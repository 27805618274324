@import '../variables.scss';

.bill-sidebar {
  color: $offBlack2019;
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  flex-basis: 0px;
  flex-grow: 1;

  .back-to-search {
    display: none;
  }

  li {
    a {
      font-weight: bold;
    }

    a,
    a:active {
      color: $highlight;
    }

    a:visited {
      color: $textControl;
    }
  }

  .btn-primary {
    background-color: $primaryLighter;
    border-color: $primaryLighter;
  }

  .btn-primary:hover {
    background-color: $primaryBolder;
    border-color: $primaryBolder;
  }

  .btn-secondary {
    background-color: $primary;
    color: #fff;
    font-weight: normal;
  }

  .btn-secondary:hover {
    background: $primaryBolder;
  }

  .btn-view-more {
    border: 0px;
    outline: none;
    background: none;
    padding: 1px 0px;
  }

  .btn-tertiary {
    color: white;
    background-color: $teal-80;
    min-width: 80px;
    border-radius: 5px;
    border: none;
    height: 25px;
    box-shadow: none;
  }

  .btn-tertiary:hover {
    background-color: $teal-100;
    border-color: $teal-100;
  }

  .btn-tertiary:active:focus {
    background-color: $teal-100;
    border-color: $teal-100;
  }

  .bill-sidebar-content {
    margin-bottom: 1rem;
  }

  section {
    h3 {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $textControl;
      font-size: 1rem;
      font-weight: bold;
      color: $textControl;
    }
  }

  .bill-meta {
    padding: 1rem;

    .bill-track-link {
      padding: 0;
    }

    .button {
      display: inline-block;
      margin-left: 1rem;
      cursor: pointer;

      path {
        fill: $secondary;
      }
    }

    .button:hover {
      path {
        fill: $primaryBolder;
      }
    }

    .button:first-child {
      margin-left: 0;
    }
  }

  .title-mobile {
    display: none;
  }

  .bill-details {
    .detail-item {
      margin: 0 0 1rem 0;
      padding-bottom: 12px;

      .promo-text {
        font-size: 14px;

        a.upgrade-link {
          color: $offBlack2019;
          text-decoration: underline;
        }
      }


      .generate-btn-group {
        display: flex;
        align-items: center;
        margin-top: 0.75rem;

        .summaries-count {
          display: flex;
          align-items: center;

          p {
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400;
          }
        }
      }

      @media (max-width: 768px) {
        padding: 12px 0;
      }

      .title {
        font-weight: 500;
        margin: 0;
        line-height: 1.5;
      }

      .ai-generated-text {
        padding-top: 10px;
      }
    }

    .version-to-version .dropdown-container {
      width: 100%;

      >.filter {
        .dropdown-title {
          max-width: 95px;
        }
      }

      @media (max-width: $xl) {
        flex-direction: column;
        justify-content: space-around;

        >.filter {
          padding: 5px 0;
        }
      }
    }

    #bill-topics-tooltip-detail {
      transform: translate(0px, -2px);
    }

    #bill-summary-tooltip-detail {
      transform: translate(0px, -2px);
    }
  }

  .annotation-comments {
    .annotation-comments-list {
      margin-bottom: 1rem;
    }

    .controls {
      text-align: right;
    }
  }

  .committee-list:after {
    content: ',\00a0';
  }

  .committee-list:last-child:after {
    content: '';
  }

  @media (max-width: 768px) {
    .back-to-search {
      display: block;
      background-color: #fff;
      padding-top: 15px;
    }

    .annotation-comments {
      background: #fff;
    }

    .title-mobile {
      display: block;
      background-color: $background-grey;
      padding: 16px;
      margin-right: -15px;
      margin-left: -15px;
      margin-bottom: 16px;
      top: 0 !important;

      .bill-title {
        font-size: 24px;
      }

      .bill-session {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .bill-details h2 {
      display: none;
    }
  }
}