@import "../variables.scss";

.bill {
  .bill-card {
    margin-top: 20px;
  }

  .bill-number {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .bill-name {
    font-size: 1rem;
  }
  .committee {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .bill-group-item {
    margin-top: 0;
    border-top: 1px solid $textLightBackground;
    .react-sanfona-item-title {
      font-size: 1.3rem;
      font-weight: normal;
      color: $textPrimary;
      line-height: 4rem;
    }
  }

  .bill-error-message {
    font-size: 24px;
    padding: 16px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 100%;
  }

  #addAnnotation {
    position: absolute;
    cursor: pointer;
  }
  #billView {
    [id^="pl"] {
      line-height: 1em;
      left: -999px;
    }
    .annotation {
      cursor: pointer;
    }
  }
  #billLineNumbers {
    .line-number {
      position: absolute;
    }
  }

  .bill-text {
    color: #000;
    line-height: 2rem;

    .vertical-break {
      .line-number-col {
        padding-top: 1rem;
      }
      .line-content-col {
        padding-top: 1rem;
      }
    }

    .centered .line-content-col {
      text-align: center;
    }

    .ceremonial-intro .line-content-col {
      font-weight: bold;
    }

    .header-1,
    .header-2,
    .header-3,
    .header-4,
    .header-5 {
      .line-content-col {
        font-weight: normal;
      }
    }
    .header-1 .line-content-col {
      font-size: 1.8rem;
    }
    .header-2 .line-content-col {
      font-size: 1.6rem;
    }
    .header-3 .line-content-col {
      font-size: 1.4rem;
    }
    .header-4 .line-content-col {
      font-size: 1.2rem;
    }
    .header-5 .line-content-col {
      font-size: 1rem;
    }

    .inline-section-text-14 {
      font-size: 1.4rem;
    }
    .inline-section-text-16 {
      font-size: 1.6rem;
    }

    // New styles dictate keeping everything left-justified
    //.indent-1 .line-content-col { padding-left: 4rem; }
    //.indent-2 .line-content-col { padding-left: 8rem; }
    //.indent-3 .line-content-col { padding-left: 12rem; }
    //.indent-4 .line-content-col { padding-left: 16rem; }
    //.indent-5 .line-content-col { padding-left: 20rem; }

    .annotation-highlight,
    .annotation-highlight * {
      background-color: yellow;
    }

    ins,
    del {
      font-weight: bold;
      text-decoration: none;
    }
    ins {
      color: #6aa84f;
    }
    del {
      color: #ff0000;
      text-decoration: line-through;
    }

    // h2 = contentHeadings in CEX markdown syntax
    // h2 {

    // }

    // h3 - h6 = sectionHeadings in CEX
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
    }
    h4,
    h5,
    h6 {
      text-transform: none;
    }

    blockquote {
      padding-left: 1.5rem;
      border-left: 3px solid $primaryLighter;
    }

    .btitle_prolog {
      display: block;
    }
    .added {
      color: blue;
    }
    .removed {
      color: red;
      text-decoration: line-through;
    }
    var {
      display: none;
    }
    .hidden {
      display: none;
    }
    .subd {
      text-indent: 4em;
    }

    h3.headnote {
      font-weight: bold;
    }
    .bill_title {
      p {
        text-indent: 12em;
      }
    }
    .section_number {
      font-weight: normal;
      padding-left: 2em;
    }
  }
  .bill-text.hide-changes {
    del {
      display: none;
    }
    ins {
      text-decoration: none;
      font-weight: normal;
      color: inherit;
    }
  }

  .source-document-embed {
    margin-top: 1rem;
  }

  .bill-version-controls {
    float: right;
    color: #7f8fa4;
    padding-left: 10px;
    margin: 0 0 10px 0;
    .inline-control {
      padding: 0.25rem 0.5rem;
    }
  }
  @media (max-width: 1056px) {
    .inline-control {
      margin-bottom: 10px;
    }
  }
}

.bill-text-markdown-parent {
  padding: 0px 10px;
}

.bill-text-row {
  display: block;
  width: 100%;

  &:first-child .line-number-col {
    border-top-left-radius: 0.25rem;
  }
  &:first-child .line-content-col {
    border-top-right-radius: 0.25rem;
  }
  &:last-child .line-number-col {
    border-bottom-left-radius: 0.25rem;
  }
  &:last-child .line-content-col {
    border-bottom-right-radius: 0.25rem;
  }
}
.line-number-col {
  margin: 0px;
  padding: 0px 5px 0px 5px;
  min-width: 3rem;
  text-align: right;
  vertical-align: top;
  box-sizing: border-box;
  background-color: #f0f3f8;
  color: #7f8fa4;
  font-family: "Open Sans";
  font-size: 0.8rem;
  display: table-cell;
}
.line-content-col {
  margin: 0px;
  padding: 0px 0px 0px 10px;
  background-color: #ffffff;
  display: table-cell;
}
