@import '../../../styles/scss/variables.scss';

.bill-annotation-tooltip-container {
  width: auto;
  max-width: 250px;
  margin: 20px;
}

.bill-annotation-tooltip {
  background-color: $dark-grey;
  color: $white;
  font-size: 14px;
  width: auto;
  max-width: 250px;
  pointer-events: auto !important;
  padding: 0;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  .tooltip-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 16px 12px 16px;

    .tooltip-button {
      font-weight: normal;
      color: $sky-blue;
      font-size: 10px;
      padding: 0 8px;
    }

    .edit {
      width: 12px;
      height: 12px;
      margin: 0 0 0 8px;
      align-self: center;
      cursor: pointer;
    }
  }

  .comment {
    margin: 0 16px;
    padding: 12px 0 16px 0;
    border-top: rgba($white, 0.2) 1px solid;
  }
}
