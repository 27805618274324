$primary: #F47F13 !default;
$primaryBolder: #f26522 !default;
$primaryLighter: #f68c24 !default;
$secondary: #7F8FA4 !default;
$textLighter: #7f8fa4 !default;
$textPrimary: #454f60 !default;
$textControl: #354052 !default;
$textLightBackground: #f0f3f8 !default;
$highlight: #196881 !default;
$greenHighlight: #00a08c !default;
$paleYellowHighlight: #D8C18C !default;
$offBlack: #231f20 !default;
$offBlack2019: #131314 !default;
$border-color: #f0f3f8;
$muted: #b7b7b7;
$transparent-orange: rgba(246, 141, 36, .122);
$side-panel-link-color: #4a90e2;

// Start of new theme colors. TODO: Clean up above
$wheat: #ffd38d;
$butterscotch: #ffb43d;
$civic-orange: #f68c24;
$very-light-blue: #eef6fc;
$sky-blue: #4aa2e2;
$background-grey: #f8f8f8;
$light-grey: #d8d8d8;
$medium-grey: #979797;
$dark-grey: #4a4a4a;
$black: #000000;
$white: #ffffff;
$jade: #1aa08f;
$purple: #955ca9;
$light-yellow: #ffd38d33;
$dark-grey-blue: #354052;
$transparent-white: rgba(255, 255, 255, .122);
$shadow-black: #0000001A;
$menu-shadow: 0px 0px 10px $shadow-black;
$slate-gray: #5f5f5f;
$hawkes-blue: #CBE9FF;
$apricot: #FFE7CE;
$rose-quartz: #B0549C;

// Start of bobby redesign
$teal-100: #1E8CA8;
$teal-90: #27A4CC;
$teal-80: #00a6d0;
$teal-50: #6AC5E2;
$teal-10: #c2effc;

$orange-100: #F79728;
$orange-80: #F9B15E;
$orange-50: #FBCB93;
$orange-10: #FDE5C9;

$purple-100: #955CA9;
$purple-50: #BD93CC;

$seagreen-100: #1AA08F;
$seagreen-10: #C7F6F0;

$gray-90: #1C1C1C;
$gray-70: #282828;
$gray-50: #666666;
$gray-40: #919191;
$gray-30: #BDBDBD;
$gray-10: #D3D3D3;
$gray-190: #E9E9E9;
$gray-06: #F2F2F2;
$gray-05: #EAEAEA;

$tan-10: #FFF5E6;

$error-red: #EB5757;
$success-green: #6AA84f;

$blue-10: #C2EFFC;

$green-10: #D5FBDB;

// Plural Style Guide
$light-mint: #C8EEED;
$mint: rgba(199, 246, 240, 0.5);
$deep-green: #002F2E;
$teal-green: #005E5C;
$misty-teal: #00cbb1;
$light-teal: #3e7d7c;
$plural-orange: #FF8427;
$muted-orange: rgba(255, 133, 39, 0.2);
$grey-05: #FBFBFB;
$grey-10: #F2F2F2;

$grey-70: #282828;
$light-gray: #F5FCFB;
$red-highlight: #f40404E8;
$primaryDarker: #b25a08;
$dark-teal: #03605d;
$golden-yellow: #fec71e;

// Screen sizes
$xs: 414px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1056px;

// z-index
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-offcanvas: 1050;
$zindex-modal: 1060;
$zindex-popover: 1070;
$zindex-tooltip: 1080;