@import '../../styles/scss/variables';

.primary-layout {
  &.with-banner {
    .primary-layout-wrapper {
      height: calc(100vh - 160px);
      margin: 0 0 0;
    }
  }

  .primary-layout-wrapper {
    height: calc(100vh - 64px);
    margin: 0 0 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .primary-layout-content {
    margin: 0 !important;
    overflow-y: auto;
    width: 100%;

    @media (max-width: 768px) {
      width: 0;

      &.sidebar-minimized {
        width: 100%;
      }
    }
  }
}

.ce-nav {
  .nav-icon {
    padding: 0px;
    margin: 0px;
    a {
      height: 40px;
      width: 56px;
      display: inline-block;
      text-align: center;
    }
    a > svg > path {
      fill: $gray-90;
    }

    &.selected {
      color: #0b3f47;
      svg > path {
        fill: $teal-80;
      }

      .selected-line {
        background-color: $teal-80;
        height: 2px;
        position: relative;
        top: 7px;
        width: 56px;
        margin-left: 0px;
      }
    }
  }

  .help-link {
    align-self: center;

    a {
      color: $textLighter;
    }

    a:hover {
      color: $black;
    }
  }

  #log-out {
    width: 100%;
  }

  .btn-link {
    color: black;
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    &:hover {
      text-decoration: 'underline';
    }
  }
}
