@import '../variables.scss';

.similar-bills {
  button.start {
    margin-top: -.5rem;
    margin-right: 0.5rem;
    float: right;
  }
  a.more {
    cursor: pointer;
    font-size: 1rem;
  }
  li {
    clear: right;
  }
}

.legislative-tracking-wrapper #right_sidebar {
  .similar-bills.react-sanfona-item {
    h3.react-sanfona-item-title {
      padding-right: 0;

      .btn-primary {
        font-size: .8rem;
        border-radius: 0;
      }
    }
  }
}
