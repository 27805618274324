@import "../../../styles/scss/variables.scss";

.list {

  .list-header,
  .bill-table-header {
    font-size: 12px;
    color: $textControl;
    padding-bottom: 16px;
    border-bottom: solid 1px $background-grey;
    margin: 0;

    .filter-group {
      .total-count {
        margin-right: 7px;
      }
    }
  }

  .list-item {
    padding: .5rem;
    margin: 15px 0;

    &:hover {
      background-color: $grey-05;
      cursor: pointer;
    }

    &:focus {
      background-color: $grey-05;
      outline: 3px solid $muted-orange;
    }

    @media (max-width: $md) {
      padding: 0;
    }

    .card-body {
      position: relative;

      .bill-sponsor-list {
        margin: 5px 0px 10px;

        .bill-sponsor-list-item {
          margin-right: 10px;
          color: $medium-grey;
          cursor: default;
        }

        .more-sponsors {
          color: $medium-grey;

          .info {
            margin-left: 4px;
          }
        }
      }

      .statement-wrap {
        .position-button {
          max-height: 35px;
          color: #ffffff;
        }

        cursor: text;

        .truncate-hover {
          width: 100%;
          max-height: 35px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: max-height 0.3s ease-in-out;
          padding: 6px 15px;

          p {
            margin-bottom: 0;
          }

          &:hover {
            max-height: 200px;
            white-space: normal;
          }
        }
      }

      @media (max-width: 576px) {
        padding: 1rem;
      }
    }

    .regulation-name {
      width: 70%;
      padding: 5px 0 15px 0;
      color: $textControl
    }

    .pre-note {
      display: inline-block;
      width: 70%;
      color: $sky-blue;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;

      @media (min-width: 576px) {
        width: 100%;
      }

      .session {
        color: $medium-grey;
        width: 100%;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        margin: 8px 0;

        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    .pre-note.card-badge {
      padding: 2px 0 10px 0;
    }

    .title,
    .title a {
      color: $black;
      font-size: 17px;
      font-weight: 600;
    }

    .title.agency-title {
      font-size: 16px;
      padding: 2px 0 25px 0;
    }

    .name {
      font-size: 22px;
      font-weight: 700;
    }

    .body {
      color: $black;
      font-size: 14px;
      font-weight: normal;
      padding: 10px 0 0px;
    }

    .footnote {
      color: $medium-grey !important;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;

      .session {
        text-align: right;

        @media (max-width: $md) {
          display: none;
        }
      }

      .footnote-extended {
        font-style: italic;
        font-weight: normal;
        text-transform: none;
        text-align: right;

        @media (max-width: $md) {
          max-width: 48%;
        }
      }
    }
  }

  .filter-header {
    .flex-row {
      padding-top: 8px;
    }
  }

  .list-header:not(.filter-header)>.total-count {
    padding-left: 16px;
    padding-top: 22px;
  }

  .sort-view-group {
    padding-left: 50px;
  }
}

#bill-list,
.tag-list,
.regulation-list {
  width: 100%;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
}

.tag-list {
  .custom-toggle-dropdown {
    top: -30px;
    float: right;
  }

  .custom-toggle-dropdown-trigger {
    width: 24px;
    height: 24px;
    text-align: center;
    color: $medium-grey;
    cursor: pointer;
  }
}

.create-tag-btn {
  @media (max-width: $md) {
    width: 100%;
    background-color: $sky-blue !important;
  }
}

#bill-list {
  .bill-tracking-btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;

    @media (max-width: 576px) {
      position: relative;
      width: 100%;
      top: auto;
      right: auto;
      margin-top: 10px;

      .btn {
        width: 100%;
      }
    }
  }
}

.timeline {
  padding-top: 20px;
  position: relative;

  .row {
    padding-bottom: 35px;
  }

  .timeline-key {
    width: 100%;
    font-size: 15px;
    left: 0;
    color: $gray-70;
    text-align: right;
    font-weight: 700;
    text-transform: uppercase;
  }

  .timeline-dot {
    position: absolute;
    top: 5px;
    left: 81.4%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $teal-green;
    z-index: 1;
  }

  .list-group-item {
    border: unset;
  }

  .timeline-entry {
    width: 90%;
  }

  .person-vote-text {
    font-size: 16px;
    color: $gray-50;
    line-height: 1.4;

    .person-vote-option {
      font-weight: 600;
    }

    .option-yes {
      color: $misty-teal;
    }

    .option-no {
      color: $error-red;
    }
  }
}

.timeline::after {
  content: '';
  color: $gray-30;
  width: 1px;
  font-size: 2px;
  position: absolute;
  border: 1px dashed;
  top: -10px;
  bottom: 0;
  left: 20%;
}

.no-results {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: $gray-50;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  margin-left: 90px;
  margin-right: 90px;

  .no-results-content {
    padding: 50px;
  }

  .no-results-icon {
    font-size: 50px;
    color: $gray-30;
    padding-bottom: 10px;
  }

  .no-results-title {
    color: $textControl;
    font-size: 28px;
    font-weight: 700;
  }

  .no-results-message {
    padding-top: 15px;
    width: 250px;
    overflow: auto;
    margin: 0 auto;

    span {
      word-wrap: break-word;
    }
  }
}