//$icon-font-path: "../../../node_modules/bootstrap/scss/bootstrap";
@import './variables.scss';
@import '~bootstrap/scss/bootstrap';

.title {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
}

h2 {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
}

h3 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;

  & strong {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
  }
}

.person-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.message-card {
  background-color: $background-grey;
  border: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: $textControl;
  width: 50%;
  padding: 40px 40px;
  margin: 50px auto;
}

// Old css that can be cleaned up as we redesign

.side-panel-link {
  color: $side-panel-link-color;
  font-size: 0.8rem;
  font-weight: bolder;
  margin-right: 1rem;
  text-transform: uppercase;

  &:hover {
    color: $textControl;
  }
}

.full-page {
  min-height: 100vh;
}

.top-right-nav {
  .mobile-only.dropdown-item {
    display: none;
  }
}

.right-nav {
  .help-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    a {
      color: $textLighter;
    }
  }

  .mobile-only {
    display: none;
  }

  .dropdown {
    span {
      color: $textLighter;
    }
  }

  .notification {
    position: relative;

    .notification-bell {
      color: $textLighter;
      font-size: 0.89rem;
    }

    .notification-indicator {
      background: $primary;
      border-radius: 50%;
      display: block;
      height: 0.5rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 0.5rem;
    }
  }
}

.bill-list-header {
  font-weight: 100;

  .bill-list-filter {
    align-self: center;
  }

  h1 {
    font-weight: 300;
  }

  a,
  .bill-list-sort {
    color: $secondary;
    padding-right: 0.5rem;
  }

  a {
    &:last-of-type {
      padding-right: 0;
    }
  }

  .selected {
    text-decoration: underline;
  }

  em {
    color: $muted;
  }
}

.notifications-wrapper {
  background: $white;
  margin-top: 2rem;
  padding: 3.5rem;
  width: 100%;

  .sort-bar {
    color: $textLighter;
    font-size: 0.8rem;

    .filter {
      cursor: pointer;
      margin-left: 0.5rem;

      &:hover {
        color: $textControl;
      }
    }

    .active {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .edit-notification-link {
    color: $textLighter;
    font-weight: 100;
  }

  .notifications-lead-row,
  .notification-row {
    border-bottom: 0.1rem solid $border-color;
    padding-bottom: 0.5rem;
    width: 100%;
  }

  .notifications-lead-row {
    border-top: 0.1rem solid $border-color;
    margin-top: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .notification-row {
    padding-bottom: 1rem;
    padding-top: 1.5rem;

    h6 {
      color: $textControl;
      font-weight: 550;
      padding-top: 1rem;
    }

    p {
      &.notification-row__text {
        font-size: 14px;
        color: #354052;
      }
    }

    a {
      color: $textControl;
      text-decoration: underline;
      transition: color 400ms ease;

      &:hover {
        color: $textLighter;
      }
    }

    .name {
      font-weight: bold;
    }
  }
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

/* react-jsonschema-form glyph replacements */
i.glyphicon {
  display: none;
}

.btn-add::after {
  content: 'add +';
}

.array-item-move-up::after {
  content: 'Move Up';
}

.array-item-move-down::after {
  content: 'Move Down';
}

.array-item-remove::after {
  content: 'x';
  font-weight: normal;
}

.admin-sublist {
  list-style: none;
}

.close-icon,
.edit-icon {
  width: 10px;
  cursor: pointer;
  pointer-events: all;
}

.enview-logo {
  .cls-1 {
    fill: #0b3f47;
  }

  .cls-2 {
    fill: url(#linear-gradient);
  }

  .cls-3 {
    fill: none;
  }

  .cls-4 {
    fill: #f78d24;
  }

  .cls-5,
  .cls-6 {
    fill: #787878;
  }

  .cls-5 {
    stroke: #787878;
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
  }
}

img.profile-photo-mini {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
}

button {
  &.btn-primary {
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0;
    text-align: center;
    min-width: 130px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  }

  &.btn-danger {
    background-color: #eb5757;
    border: 1.5px solid #eb5757;
  }
}

.primary-layout {
  height: 100vh;
  overflow: hidden;

  .btn-primary {
    background-color: $teal-80;
    border-color: $teal-80;
    font-size: 12px;
    font-weight: 600;
  }

  .btn-primary:hover {
    background-color: $teal-100;
    border-color: $teal-80;
  }

  .btn-danger {
    background-color: #eb5757;
    border-color: #eb5757;
  }

  .btn-white,
  .btn-orange {
    font-size: 12px;
    padding: 9px 16px;
  }

  .btn-secondary {
    padding: 0.5rem 1.25rem;
    background-color: $light-grey;
    color: $textControl;
    border: 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .btn-secondary.compact {
    padding: 4px 8px;
  }

  .btn-dark {
    background-color: $textControl;
    color: #fff;
  }

  .btn.loading {
    opacity: 0.5;
  }

  .btn.full-width {
    width: 100%;
    text-align: center;
  }
}

.pageset {
  padding-top: 2rem;
  padding-bottom: 2rem;

  h1.title {
    font-size: 30px;
  }

  .nav-pills {
    .nav-link {
      color: $textPrimary;
      font-weight: bold;
    }

    .nav-link.active {
      color: $textPrimary;
      background: transparent;

      .link-text {
        border-bottom: 3px solid $greenHighlight;
      }
    }
  }

  #content {
    padding: 2rem;
    margin-top: 2rem;
    background-color: #fff;

    // form {
    //   input.form-control, select.form-control {
    //     // max-width: 50%;
    //   }
    // }
  }
}

@media (max-width: 768px) {
  .pageset {
    #content {
      form input.form-control {
        max-width: 100%;
      }
    }
  }
}

.bg-secondary-light {
  background-color: $textLightBackground;
}

.focus-layout-container {
  background-image: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 20px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  .container {
    flex-grow: 1;

    .row {
      margin-right: -50px;
      margin-left: -50px;
    }

    // update?
    .text-gray {
      color: $textControl;
    }
  }

  .logo.thin {
    width: 200px;
    margin: 4rem auto 2rem auto;
  }

  .logo.wide {
    display: none;
  }

  .card {
    background-color: rgba(240, 243, 248, 0.5);
    border: none;
    padding: 20px 40px;
    font-size: 0.875rem;
    font-weight: 600;
    color: $textControl;

    label {
      display: none;
    }

    h3 {
      font-size: 1.5rem;
    }

    .checkbox label {
      display: block;
      font-size: 0.75rem;
      font-weight: normal;
    }

    input.form-control {
      font-size: 0.875rem;
    }

    input.form-control::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-style: italic;
      font-size: 0.875rem;
    }

    input.form-control:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-style: italic;
      font-size: 0.875rem;
    }

    input.form-control::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-style: italic;
      font-size: 0.875rem;
    }

    select.form-control {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.875rem;
    }

    .login-btn {
      position: relative;
      text-align: right;
      margin-top: -56px;
      width: 50%;
      float: right;
    }

    .login-help {
      text-align: center;
    }
  }

  .btn-primary {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 10px 20px;
    min-width: 100px;
  }

  .signup-link {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline !important;
    color: $textControl !important;
    cursor: pointer;
  }

  .legal-footer {
    color: $textControl;
    margin-bottom: -1rem;
  }
}

.filter-header,
.bill-table-header,
.result-total.jurisdiction-filter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .filter-group {
    padding: 0px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter {
      margin-right: 10px;
    }
  }

  .sort-group {
    margin-left: auto;
    margin-right: 20px;
  }

  .actions-view-group {
    display: flex;
    align-items: center;
  }

  .view-dropdown {
    padding: 0;
    margin-left: 10px;
  }

  @media (max-width: $lg) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .sort-group {
      margin-left: 0px;
    }
  }

  @media (max-width: $sm) {
    .filter-group {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 20px;

      .total-count,
      .jurisdictions {
        margin-bottom: 4px;
      }
    }

    .sort-group {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      margin: 0px;

      .sort-by {
        margin-left: 0px;

        span {
          margin-left: 0px;
        }
      }
    }

    .dropdown-container {
      margin: 0px 20px;
    }
  }
}

@media (max-width: 768px) {
  .focus-layout-container {
    padding-top: 0;
    display: flex;
    flex-direction: column;

    .container {
      flex-grow: 1;
      margin-bottom: 4rem;

      .row {
        margin-right: -20px;
        margin-left: -20px;
      }
    }

    .logo.thin,
    .logo.wide {
      display: block;
      width: auto;
      padding: 1rem;
      margin: 0 -20px 3rem -20px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .enview-logo {
      width: 125px;
    }

    .card {
      border: 0;
      padding: 40px 30px;
      background-color: rgba(240, 243, 248, 0.5);
      color: $textControl;

      a {
        color: $primary;
        text-decoration: none;
      }

      .card-body {
        padding: 0;
      }

      button.btn-primary {
        width: 100%;
        padding-right: 2rem;
        padding-left: 2rem;
        background: 0;
        background-color: $primary;
        border: 2px solid $primary;
        color: #fff;
      }

      button.btn-primary:hover {
        background: rgba(255, 255, 255, 0.4);
      }

      button.btn-secondary {
        margin-right: 1rem;
        border: 0;
      }

      input.form-control {
        padding: 0.75rem;
        border-radius: 0.5rem;
        color: $textControl;
      }

      // Removing these for now as they interfere with
      // the visibility of placeholders in the input fields
      // input.form-control::placeholder {
      //   color: rgba(255, 255, 255, 0.9);
      // }
      // input.form-control:-ms-input-placeholder {
      //   color: rgba(255, 255, 255, 0.9);
      // }
      // input.form-control::-ms-input-placeholder {
      //   color: rgba(255, 255, 255, 0.9);
      // }
    }

    .signup-header p {
      margin: 2rem 0;
    }

    .login-form,
    .signup-form {
      .form-group:last-child {
        margin-bottom: 0;
      }

      .login-btn {
        margin: -15px 0 0 0;
        width: 100%;
      }

      .login-help {
        p {
          text-align: center;
        }
      }

      .authorization-help {
        display: block;
        margin: 1rem auto;
        text-align: center;
        font-size: 90%;
        font-style: italic;

        span {
          display: block;
        }
      }

      .submit {
        margin-top: 1.5rem;
      }
    }
  }
}

.site-banner {
  background-color: $highlight;
  color: #fff;
  text-align: center;
  line-height: 64px;
  font-size: 1.2rem;
  z-index: 99;

  strong {
    color: $paleYellowHighlight;
  }

  a,
  a:visited,
  a:active {
    color: #fff;
    text-decoration: underline;
  }
}

@media (max-width: 768px) {
  .site-banner {
    font-size: 0.8rem;
  }
}

.new-top-nav {
  font-family: 'DM Sans', sans-serif;
}

.ce-nav,
.new-top-nav {
  display: flex;
  background: #fff;
  box-shadow: 0px 4px 10px 0px #0000000d;
  height: 64px;
  width: 100%;
  padding: 20px;
  z-index: 99;
  align-items: center;

  .mobile-menu-icon {
    display: none;
    width: 14px;
  }

  ul,
  .top-right-nav {
    margin: 0;

    .top-nav-link {
      color: $teal-green;
      font-size: 14px;
    }

    .trial-message {
      font-size: 14px;
      color: $gray-50;
    }

    >li,
    .top-nav-item {
      display: inline-block;
      margin: 0 10px;

      a {
        color: #000;

        &:active {
          color: #000;
        }

        &:hover {
          color: #000;
        }
      }

      &.dropdown {
        >span {
          display: inline-block;
          padding-left: 14px;
          padding-right: 22px;
          background-image: url('../../static/dropdown-secondary.png');
          background-repeat: no-repeat;
          background-position: right 0 center;
        }

        ul {
          display: none;
        }

        &:hover {
          ul {
            background: #fff;
            display: block;
            position: absolute;
            border-radius: 5px;
            padding: 5px;
            min-width: 10rem;
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.09);
          }
        }
      }
    }

    .top-nav-item.help-icon {
      margin: unset;
    }

    @media (max-width: 767px) {
      .desktop-nav-item {
        display: none;
      }
    }
  }

  .branding {
    padding: 0 1rem 0 0;
    z-index: 99;
    line-height: 24px;
    flex-grow: 1;

    a {
      display: flex;
      align-items: center;
    }

    .enview-logo {
      display: inline-block;
      width: 140px;
      margin-left: -8px;
    }

    .organization {
      display: inline-block;
      color: $primary;
      margin-left: 20px;
    }
  }

  .new-branding {
    a {
      display: unset;
    }
  }

  .center-nav {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;

    ul {
      li {
        a {
          &:hover {
            text-decoration: none;
          }
        }

        &.active,
        &:hover {
          border-bottom: 3px solid $primary;
        }
      }
    }
  }

  .right-nav {
    ul {
      padding-left: 0;
      display: inline-block;
    }
  }

  .inbox-icon {
    width: 30px;
    margin-bottom: -0.25rem;
  }
}

@media (max-width: 768px) {
  .ce-nav {
    .right-nav ul {
      margin-bottom: 0;
    }

    .right-nav ul>li.dropdown ul {
      right: 0;
      margin-top: -4px;
      border-top-right-radius: 0;
    }

    .right-nav ul>li {
      display: block;

      &.mobile-only {
        display: block;
      }
    }

    ul>li.dropdown>.desktop-menu-label,
    .right-nav ul>li.help-link {
      display: none;
    }

    .mobile-menu-icon {
      display: unset;
    }

    li.dropdown.inactive ul,
    li.dropdown.inactive:hover ul {
      display: none;
    }

    .branding {
      padding-left: 1rem;

      .enview-logo {
        width: 125px;
      }
    }
  }
}

// Banner adjustments
.with-banner .ce-nav {}

.tags-list {
  li.list-group-item {
    z-index: 0;
  }

  .controls {

    .edit-icon,
    .close-icon {
      width: 14px;
      margin-left: 2em;
    }
  }
}

.list-group-flush .list-group-item:first-child {
  border-top: 0;
}

.team-list {
  li.list-group-item {
    z-index: 0;
  }

  .controls {

    .edit-icon,
    .close-icon {
      width: 14px;
      margin-left: 2em;
    }
  }
}

.edit-tag {
  .value {
    .color {
      padding: 3px 8px;
      display: inline-block;
      border: 1px solid #999999;
      border-radius: 3px;
      line-height: 1rem;
    }
  }

  .option {
    .color {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid #999999;
      border-radius: 3px;
    }

    .value {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.primary-layout-wrapper {
  display: flex;
  align-items: stretch;

  #primary-sidebar {
    transition: all 0.3s;

    section {
      padding: 1rem;
      border-bottom: 1px solid $textLightBackground;

      &:nth-of-type(2) {
        border-bottom: none;
      }
    }

    section:last-child {
      border-bottom: 0;
    }

    .hide {
      padding-right: 0.25rem;
      text-align: right;
      cursor: pointer;

      path {
        fill: $textLighter;
      }
    }

    .profile-card {
      height: 5rem;
      overflow: hidden;
      color: $textPrimary;

      .additional {
        transition: all 1s;
        margin-left: 50px;

        p {
          margin: 0.25rem 0;
        }
      }
    }

    .react-sanfona-item {
      clear: left;
    }

    .react-sanfona-item-title {
      path {
        fill: $textLighter;
      }

      .expanded-title {
        max-width: 250px;
        margin-left: 1rem;
        overflow: hidden;
        transition: all 1s;
        white-space: nowrap;
      }
    }

    .edit-tag {
      display: none;
      float: right;
      cursor: pointer;
    }

    .react-sanfona-item p:hover .edit-tag {
      display: block;
    }

    .bill-geo {
      padding-right: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 90%;
      color: $textPrimary;
    }

    .icon {
      display: inline;
    }

    .mobile-nav {
      display: none;
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 1rem;
      }

      a {
        color: $textPrimary;
      }
    }

    .react-sanfona-item-body {
      max-height: none;
      overflow: visible;
      transition: max-height 300ms ease 0s;
    }

    .react-sanfona-item-body.hidden {
      max-height: 10px;
      overflow: hidden;
      transition: max-height 300ms ease 0s;
    }
  }
}

.legislative-tracking-wrapper {
  display: flex;
  align-items: stretch;
  overflow: hidden;

  #content {
    display: flex;
    width: 100%;
    overflow: auto;
  }

  .main-pane {
    display: flex;
    flex-direction: column;

    .bill-text .react-sanfona-item-title {
      display: none;
    }
  }

  .legislative-tracking-filter {
    width: 100%;
  }

  #primary-sidebar {
    .edit-tag {
      float: right;
      cursor: pointer;
    }
  }

  #right_sidebar {

    section,
    .react-sanfona-item {
      padding: 1rem 0;
      border-bottom: 1px solid $textLightBackground;
    }

    section:last-child {
      border-bottom: 0;
    }

    .react-sanfona-item,
    .react-sanfona-item h2.react-sanfona-item-title {
      margin: 0;
    }

    .annotation {
      padding: 0.5rem 0 0.5rem 0;

      .profile-photo-mini {
        margin-top: -0.25rem;
        margin-right: 0.25rem;
      }

      .annotation-comment {
        height: auto;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }

      .annotation-comment.annotation-comment-italics {
        font-style: italic;
      }

      .annotation-footer {
        margin-top: 10px;
        font-size: 10px;
        font-style: italic;
        text-align: right;

        .annotation-name {
          color: #354052;
        }

        .annotation-time {
          color: #7f8fa4;
        }

        .annotation-edit-icon {
          float: right;
          margin-left: 1rem;
        }
      }
    }
  }

  #primary-sidebar.active {
    margin-left: -250px;
  }

  .bill-status,
  .sponsors {
    text-transform: capitalize;
  }

  .bill-state {
    color: $primaryLighter;
    font-size: 18px;
    letter-spacing: 0;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    #right_sidebar {
      .annotation {
        margin: 0.5rem 0;
      }

      .react-sanfona-item {
        padding: 0;
        margin-bottom: 0.5rem;
      }

      h3.react-sanfona-item-title {
        font-size: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      .react-sanfona-item-body-wrapper {
        background: #fff;
      }

      section {
        padding: 0.75rem 1rem 0.75rem 1rem;
      }
    }

    #sidebar.active {
      margin-left: 0;
    }

    .main-pane {
      #hide-search {
        display: none;
      }

      .bill-text .react-sanfona-item-title {
        display: block;
      }
    }
  }
}

.bill-share-modal {
  input {
    width: 80%;
    padding: 0.175rem 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  button {
    margin: 3px 5px;
    padding: 7px;
    display: inline-block;
    font-size: 1rem;
    vertical-align: 5%;
  }

  .close-icon {
    float: right;
  }
}

.optional-login-modal {

  button.unstyled {
    background: none;
    border: none;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-weight: 400;
  }
}

.react-sanfona-item {
  &.react-sanfona-item-expanded {
    .react-sanfona-item-title {
      .fa-caret-down {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  margin-top: 20px;

  .react-sanfona-item-title {
    position: relative;
    font-size: 1.25rem;
    font-weight: bold;
    cursor: pointer;
    color: $textControl;

    span {
      padding-left: 10px;
    }

    .fa-caret-down {
      float: right;
    }

    .icon {
      float: left;
      display: block;
      margin: -0.3rem auto 0 auto;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;
      background-color: #f7f7f7;
      border-radius: 50%;

      svg {
        width: 100%;
        height: 100%;
      }

      path {
        fill: $primary;
      }
    }
  }

  .react-sanfona-item-body-wrapper {
    padding: 10px 0;

    ul li a {
      font-size: 14px;
    }
  }
}

.react-sanfona-item.disabled {
  .react-sanfona-item-title {
    cursor: auto;
  }
}

.results-pane {
  .container {
    color: $medium-grey !important;
    font-size: 10pt;
  }

  .result-count {
    vertical-align: middle;

    .result-total {
      padding: 6px 15px;
    }

    .table-result-total {
      padding: 30px;
    }

    & .result-dropdown-container {
      display: flex;
      flex-direction: column;

      .total-count {
        padding-top: 12px;
      }
    }
  }
}

.filters-pane {
  padding: 0 2rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.bills-filter {
  padding: 15px 0;
  background-color: white;

  .dropdown-button {
    margin: 0 16px 0px 0;
    flex-basis: 20%;
  }

  .dropdown {
    flex-basis: 20%;
  }

  .flex-filter-control {
    display: flex;
    align-self: center;
    color: $gray-70;
    font-size: 14px;
  }

  .bill-search-input {
    border: none;
    width: 100%;
    background: url('../../static/icons/magnifier-tool.svg') no-repeat left center;
    background-size: 1.5rem;
    padding-left: 2rem;
  }

  .option {
    .option-chk {
      margin-right: 5px;
    }

    .value {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .filter-values {
    .Select-control {
      border: none;

      .Select-arrow-zone {
        display: none;
      }

      .Select-input {
        display: none;
      }

      .Select-value {
        border: none;
        color: $textControl;
        background: none;
        font-weight: bold;

        .Select-value-icon {
          display: none;

          &:hover {
            background: none;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }

      .Select-clear-zone {
        display: none;
      }
    }

    .Select-menu-outer {
      display: none;
    }
  }

  .filters-applied button {
    border: 0;
    background: transparent;
    font-size: 80%;
    color: $offBlack;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    padding-top: 16px;

    .badge {
      padding: 0;
      background: transparent;
      font-size: 120%;
      color: $textLighter;

      .close-icon {
        width: 8px;
      }
    }
  }
}

.bill.teaser,
.no-bills-found {
  margin-top: 25px;
  color: $textPrimary;
}

/** Account settings **/
.account-settings-form {
  width: 100%;

  label {
    font-size: 14px;
    display: block;
  }

  input[type='checkbox'] {
    float: right;
  }

  input[type='checkbox']:checked::before {
    position: absolute;
  }
}

#root_emailNotification__title {
  width: 186px;
  height: 24px;
  font-weight: bold;
  font-size: 18px;
  color: #354052;
  letter-spacing: 0;
}

.policy-areas,
.tags {
  .card {
    flex: 0 0 75%;
    max-width: 75%;
    margin: 1rem auto;
  }

  .controls {
    margin: 0 0 2rem 0;
  }

  .controls a {
    text-decoration: underline;
  }

  button.btn-light {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid $secondary;
    text-align: left;
    color: $offBlack;
  }

  button.btn-light:not(:disabled):not(.disabled).active {
    background-color: $highlight;
    border: 1px solid $highlight;
    color: #fff;
  }

  .actions {
    margin: 2rem 0;

    .col-md-12 {
      padding: 0;
    }
  }

  h3 {
    text-align: center;
  }
}

.tag-settings-form {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  legend,
  label {
    display: none;
  }

  .array-item-list {
    display: block;
    float: left;
  }

  fieldset.field-array>div {
    clear: left;
  }

  input.form-control {
    max-width: 400px;
  }

  input.form-control::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  input.form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .array-item {
    width: 100%;
  }

  .array-item>div {
    float: left;
  }

  button.btn {
    font-size: 120%;
  }

  .array-item-add button.btn.btn-info.btn-add {
    background-color: $highlight;
  }

  button.btn.btn-danger.array-item-remove {
    margin-left: 1rem;
    padding: 0.25rem;
  }

  button.btn.btn-danger.array-item-remove:hover {
    background-color: #dc3545;
  }
}

/** Onboarding **/
.onboarding {
  .nav-onboarding {
    margin-top: 2rem;
    text-align: right;

    button {
      padding-right: 2rem;
      padding-left: 2rem;
      background-color: $primary;
      border: $primary;
      color: #fff;
    }

    button:hover {
      background-color: #d76c0a;
      border-color: #cb6609;
    }

    button.skip {
      margin-right: 1rem;
      border: 0;
    }
  }

  .card {
    border: 0;
    margin: 0;
    max-width: 100%;
    flex: auto;
    background: transparent;
  }

  .card-body {
    padding: 0;
  }

  .tags {
    h3 {
      display: none;
    }
  }

  .policy-areas {
    h3 {
      display: none;
    }

    .container {
      padding: 0;
      margin: 0;
    }

    p {
      font-size: 1.25rem;
    }

    .controls {
      margin: 2rem 0;
    }

    .controls a {
      text-decoration: underline;
    }

    button.btn-light {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
      text-align: left;
      color: $textControl;
    }

    .row {
      margin: -15px;
      padding: 0px;
    }
  }

  /* react-jsonschema-form uses bootstrap 3 classes, but we are using bootstrap 4, so some resets are needed */
  .tag-settings-form {
    margin-top: 3rem;

    input.form-control {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      border: 0;
    }

    .array-item-add button.btn.btn-info.btn-add:hover {
      background-color: #fff;
      color: #f47f13;
    }

    button.btn.btn-danger.array-item-remove,
    .array-item-add button.btn.btn-info.btn-add {
      background-color: transparent;
      border: 0;
    }
  }
}

@media (max-width: 768px) {
  .onboarding {
    .policy-areas {
      button.btn-light {
        padding: 0.75rem;
        border-radius: 0.5rem;
      }
    }

    .nav-onboarding {
      margin-top: 2rem;

      button {
        width: 100%;
      }

      button.skip {
        margin-bottom: 2rem;
        opacity: 0.8;
        font-style: italic;
        text-decoration: underline;
        font-size: 90%;
      }
    }
  }
}

.breadcrumb-link {
  position: relative;
  padding-left: 10px;
  color: $black;

  span {
    padding-left: 10px;
  }

  &:hover {
    color: $secondary;
  }
}

.enview-filter {
  .group-select-controls {
    white-space: nowrap;
  }
}

.popover-confirm-container {
  width: 300px;
  padding: 1em;
  background-color: #f68c24;
  color: $textLightBackground;

  .popover-confirm-content {
    text-align: center;

    button {
      font-size: 80%;
      padding-right: 1rem;
      padding-left: 1rem;
      background: 0;
      border: 1px solid #fff;
      color: #fff;
    }

    button:hover {
      background: rgba(255, 255, 255, 0.4);
    }

    button.skip {
      margin-right: 1rem;
      border: 0;
    }

    button:first-child {
      margin-right: 1em;
    }
  }
}

.centralized-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.find-tip-modal {
  position: fixed;
  top: 27%;
  left: 86%;
  transform: translate(-50%, -50%);

  &__text {
    font-weight: bold;
  }

  &__span {
    font-weight: normal;
  }

  &__command {
    background-color: #fff;
  }
}

@media (max-width: 1024px) {
  .find-tip-modal {
    visibility: hidden;
  }

  .bill-track-link {
    padding: 0 !important;
  }
}

.find-tip-overlay {
  opacity: 1;
  transition: opacity 0.2s;

  &.in {
    opacity: 1;
  }
}

.find-tip-modal-content {
  width: 329px;
  height: 100%;
  font-size: 14px;
  border-radius: 5px;
  color: #354052;
  background-color: $textLightBackground;

  button {
    font-size: 80%;
    padding-right: 1rem;
    padding-left: 1rem;
    border: 1px solid #fff;
    color: #fff;
  }

  button.skip {
    margin-right: 1rem;
    border: 0;
  }

  button:first-child {
    margin-right: 1em;
  }
}

.tip-controls {
  padding-left: 1.5rem !important;
}

button.find-tip-modal-content {
  border: 1px solid #ffffff;
  background: none;
}

.bill-track-link {
  padding: 1.9rem 1.8rem 1.9rem 0;
}

@media (max-width: 810px) {
  .bill-track-link {
    margin: 5px;
  }
}

.comment-bubble {
  color: #ffffff;
  font-size: 14px !important;
}

.upload-profile-photo {
  float: right;
}

img.profile-photo {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 180px;
}

@media (max-width: 768px) {
  .upload-profile-photo {
    text-align: center;
  }

  .profile-photo {
    margin: 0 auto;
  }
}

.upload-btn {
  border: 1px solid #f68c24;
  display: none;
  color: #f68c24;
}

input[type='file'] {
  display: none;
}

.track-checkbox {
  margin-right: 2px;
  vertical-align: middle;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.is-disabled {
  text-decoration: none;
  color: currentColor;
  cursor: not-allowed;
  display: inline-block;
  /* For IE11/ MS Edge bug */
  opacity: 0.5;
  font-style: italic;
  pointer-events: none;
}

.add-note {
  padding-bottom: 14px;

  .close-icon {
    float: right;
  }
}

@media print {
  nav.ce-nav {
    .right-nav {
      display: none;
    }
  }

  .bill {
    .back-to-search {
      display: none;
    }
  }

  #primary-sidebar {
    display: none;
  }
}

.team-row {
  padding-top: 30px;
  padding-bottom: 24px;
}

.team-member-name {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}

.team-member-email {
  font-weight: normal;
  font-size: 14px;
}

/* Invite Team Panel styles */

.sidebar {
  position: relative;
}

.sidebar-content {
  margin-top: 89px;

  .sidebar-content__header {
    margin-top: 24px;
    font-size: 18px;
    font-weight: bold;
    color: #354052;
  }

  .sidebar-content__label {
    margin-bottom: 0px;
    font-size: 18px;
  }

  .sidebar-content__text {
    width: 90%;
    display: inline-block;
    font-size: 14px;
  }

  .sidebar-content__radio {
    float: right;
  }

  .sidebar-content__emails--label {
    font-size: 15px;
    color: #354052;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .sidebar-content__emails--text {
    font-style: italic;
    font-size: 15px;
  }
}

.panel-wrapper {
  position: fixed;
  top: 64px;
  bottom: 0;
  right: -520px;
  background-color: #fff;
  border: solid 1px #f0f3f8;
  width: 520px;
  padding: 30px;
  overflow-y: scroll;
  transition: all 0.5s ease;
  z-index: 4;

  &.show {
    right: 0px;
  }
}

@media (max-width: 768px) {
  .panel-wrapper {
    width: 0%;

    &.show {
      width: 90%;
    }
  }
}

.anno-visibility {
  width: 100%;

  label {
    font-size: 14px;
    display: block;
    margin: 0;
  }
}

.delete-team {
  margin-top: 60px;
}

.team-btn {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #7f8fa4;
  background-color: #ffffff;
}

.warning-modal {
  max-width: 300px;
  margin: 4rem auto;
  border-radius: 0.25rem;
  background-color: #f0f3f8;
  padding: 20px;
}

.flash-error {
  font-size: 0.85rem;
  text-align: center;

  a {
    color: #354052;
    font-weight: 700;
    text-decoration: underline;
  }
}

.card-body {
  p {
    padding: 0px 10px;
  }
}

.bill-data-quality-report-jurisdiction {
  width: 45%;
  float: left;
  padding: 0 2rem 1rem 0;

  dt {
    float: left;
    clear: both;
  }

  dd {
    float: right;
  }
}

.list-group-header {
  font-weight: bold;
  list-style-type: none;
}

.list-paragraph-style {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;

  li {
    margin: 0 0 1rem 0;
    padding-left: 0;
  }
}

.committee-selector {
  height: 40px;
  border-radius: 5px;
}

.hidden-button {
  display: none;
}

.contained-button {
  min-width: 80px;
  max-width: 300px;
}

.select-control {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.85rem;
  color: $textLighter;
  margin: 0 1rem 1rem 0;
}

.public-access-banner {
  cursor: pointer;
}

.not-login-container {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;

  p {
    height: 1.15rem;
    text-align: center;
    color: #354052;
    margin-bottom: 1.25rem;
    text-align: center;
  }

  button {
    width: 196px;
    font-size: 0.75rem;
    border: 0;
  }

  .header {
    height: 1.5rem;
    font-size: 1.125rem;
  }

  .login {
    background-color: #196881;
  }

  .signup {
    background-color: $primary;
  }

  .branding {
    padding: 0 1rem 0 0;
    z-index: 99;

    .enview-logo {
      display: inline-block;
      width: 140px;
    }

    .organization {
      display: inline-block;
      color: $primary;
      margin-left: 20px;
    }
  }
}

.missing-bill-list {
  cursor: pointer;
  text-decoration: underline;
}

.grid-ordered-list {
  ul {
    -moz-column-count: 4;
    -moz-column-gap: 20px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
    column-gap: 20px;
    list-style-type: none;
  }
}

.ordered-list {
  ul {
    list-style-type: none;
  }
}

.form-group {
  .leg-list-checkbox {
    -moz-column-count: 5;
    -moz-column-gap: 20px;
    -webkit-column-count: 5;
    -webkit-column-gap: 20px;
    column-count: 5;
    column-gap: 20px;
    list-style-type: none;
  }
}

.warning {
  text-align: center;
  font-size: 14px;
}

.search-bar {
  margin-bottom: 15px;
  width: 100%;
  position: relative;

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: $teal-90;
    font-size: 16px;
  }

  .tag-search-input {
    border-radius: 8px;
    padding: 8px 12px 8px 36px;
    width: 100%;
    border: 1px solid $gray-05;

    &:focus {
      border: 2px solid $gray-40;
      outline: none;
    }
  }
}


@media (max-width: 768px) {
  .form-group {
    .leg-list-checkbox {
      -moz-column-count: 2;
      -moz-column-gap: 20px;
      -webkit-column-count: 2;
      -webkit-column-gap: 20px;
      column-count: 2;
      column-gap: 20px;
      list-style-type: none;
    }
  }
}

//    Labels
label {
  display: flex;
}

//   Page Wrapper
.page-wrapper {
  margin-left: 36px;
  margin-right: 36px;

  @media (max-width: 768px) {
    margin-left: 12px;
    margin-right: 12px;
  }
}

//   Checkbox
input[type='checkbox'] {
  float: left;
  border: 1px solid $gray-70;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-color: $white;
  color: $white;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 12px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  align-self: center;
}

input[type='checkbox']:hover {
  color: $white;
  background-color: $seagreen-100;
  border-color: $seagreen-100;
}

input[type='checkbox']:active {
  color: $white;
  background-color: $seagreen-100;
}

input[type='checkbox']:checked {
  color: $white;
  border: none;
  background: url('../../components/svg/ic_white_check.svg') no-repeat center $seagreen-100;
}

input[type='checkbox']:indeterminate {
  border: none;
  background: url('../../components/svg/ic_white_line.svg') no-repeat center $seagreen-100;
}

// Radio button
input[type='radio'] {
  border: 1px solid $gray-70;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-color: $white;
  color: $sky-blue;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  margin-right: 12px;
  border-radius: 100%;
  cursor: pointer;
  outline: none;
  align-self: center;
}

input[type='radio']:hover {
  color: $white;
  background-color: $seagreen-10;
  box-shadow: inset 0 0 0 2px;
}

input[type='radio']:checked {
  color: $white;
  background: $seagreen-100;
  box-shadow: inset 0 0 0 2px;
}

input[type='radio']:disabled {
  color: $white;
  background-color: $white;
}

.value-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $mint;
  color: $seagreen-100;
  border-radius: 12px;
  padding: 3px 7px;
  font-size: 12px;
  margin-left: 8px;
}

// tooltip
.tooltip,
.tooltip-content {
  z-index: $zindex-tooltip !important;

  >div.sponsors-tooltip {
    .tooltip-header {
      text-decoration: underline;
      margin-bottom: 10px;
      font-weight: 800;
    }

    ul {
      padding: 0px;
      color: $white;

      li {
        list-style: none;
        text-indent: 0;
        cursor: text;

        a {
          cursor: pointer;
          color: $grey-10;
        }
      }
    }
  }
}

@import './components/teamcontrols';
@import './components/team-invitation-requests.scss';
@import './components/bill.scss';
@import './components/bill-sidebar.scss';
@import './components/similar-bills.scss';
@import './components/print-bill.scss';
@import './components/promo-modal.scss';