@import '../../../styles/scss/variables.scss';

#new-primary-sidebar {
    background-color: $grey-05;
    font-family: 'DM Sans', sans-serif;
    width: 270px;
    height: calc(100% - 70px);
    border: 1px solid $gray-06;
    cursor: default;
    position: static;
    overflow-y: overlay;

    @media (max-width: 768px) {
        width: 100%;
        min-width: unset;
        max-width: unset;

        &.sidebar-minimized {
          width: 0;

          .export-nav-group {
            display: none;
          }
        }
      }

    .org-sidebar-section {
        padding: 10px 10px 30px 10px;
    }

    .main-nav-group {
        padding-bottom: 12px;
        border-bottom: 1px solid #F2F2F2;

        a {
            &.disabled {
                pointer-events: none;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-group {
        border-bottom: 1px solid #F2F2F2;

        .card:first-child {
            margin-top: 5px;
        }
    }

    h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: $gray-70;
    }

    h3 {
        padding-bottom: 5px;
        padding-top: 20px;
    }

    h4 {
        font-size: 14px;
        line-height: 19px;
    }

    .accordion-open > .card-header > .sidebar-caret {
        animation: rotate-up 0.25s ease-in-out forwards;
    }

    .accordion-close > .card-header > .sidebar-caret  {
        animation: rotate-down 0.25s ease-in-out forwards;
    }

    @keyframes rotate-up {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-180deg);
        }
    }

    @keyframes rotate-down {
        from {
            transform: rotate(-180deg);
        }
        to {
            transform: rotate(0deg);
        }
    }


    .card {
        border: unset;
        box-shadow: unset;
        position: static;

        &.disabled {
            pointer-events: none;
        }

        .card-header {
            border: unset;
            background-color: $grey-05;

            &:hover, &:active, &:focus {
                background-color: $light-gray;
            }

            .title {
                &:hover, &:active, &:focus {
                    color: $teal-green;
                }
            }

            .sidebar-icon {
                width: 25px;
                margin-right: 10px;

                > svg > path {
                    fill: black;

                    &:hover, &:active, &:focus {
                        fill: $teal-green !important;
                    }
                }
            }

            .sidebar-caret {
                margin-right: 0;
                margin-left: auto;

                > svg > path {
                    &:hover, &:active, &:focus {
                        fill: $teal-green !important;
                    }
                }
            }
        }

        .card-body {
            background-color: $grey-05;
            padding: 5px 20px 15px 30px;

            a {
                color: $gray-70;
                font-size: 14px;
                line-height: 19px;

                &:hover {
                   color: $plural-orange;
                   text-decoration: none;
                }
            }

            .sub-item {
                padding-left: 25px;

                .link-tooltip {
                    max-width: 250px;
                    position: fixed;
                }
            }

            .sub-item.saved-search-item {
                padding-left: 2px;
            }

            .saved-search-bell-shared {
                padding-left: 5px;
            }
        }
    }

    .saved-nav-group {
        .saved-nav-group-header {
            padding: 25px 0 5px 12px;
            font-weight: 600;
            line-height: 19px;
            color: $gray-40;
        }

        .card-body {
            max-height: 210px;
            overflow-y: overlay !important;

            span {
                word-break: break-all !important;
            }
        }
    }

    .export-nav-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 237px;
        height: 70px;
        background-color: white;
        padding: 12px 0;

        .export-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $teal-green;
            border-radius: 4px;
            font-weight: 400;
            width: 50%;
            margin-left: 20px;
        }
    }

    .active-nav-link {
        color: $plural-orange !important;
        font-weight: 800;
    }

    .saved-search-tooltip {
        word-wrap: break-word;
    }
}
