@import "../../../styles/scss/variables";

.react-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.react-modal {
  max-width: 600px;
  margin: 4rem auto;
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 4px $shadow-black;
  border-radius: 10px;

  &:focus {
    outline: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-size: 16px;
    color: $gray-50;

    label{
      color: #1c1c1c;
      font-size: 16px;
      font-weight: bold;
    }

    input[type="text"]{
      width: 468px;
      height: 40px;
      background-color: #f2f2f2;
      border-width: 0;
      border-radius: 6px;
      margin-bottom: 8px;
    }
    .example{
      font-size: 16px;
      font-style: italic;
      color: #666666;
      font-weight: 400;
      margin-bottom: 24px;
    }

    .btn-cancel{
      font-size: 14px;
      color: #da5151
    }

    .title {
      margin-bottom: 16px;
      color: $gray-90;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    .error-msg {
      color: $error-red;
    }
  }

}
.confirmation-popup {
  opacity: 1;
  transition: opacity 1s linear;
  position: absolute;
  width: 352px;
  min-height: 100px;
  right: 45px;
  bottom: 2vh;
  padding: 24px;
  background-color: #ffffff;
  z-index: 100;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  .title {
    font-size: 16px;
    margin-left: 34px;
  }
  p {
    margin-left: 34px;
    max-width: 240px;
    margin-bottom: 0;
  }
  a {
    color: #27A4CC;
  }
  a.learn-more{
    font-size: 14px;
    font-weight: 600;
  }
  .check-icon {
    float: left;
  }

  &.fade-out {
    opacity: 0;
  }
}

#report-problem-modal {
  .form-label {
    padding-top: 10px;
    padding-bottom: 8px;
  }

  textarea {
    height: 120px;
  }

  .modal-footer {
    border: unset;

    #report-problem-submit-btn:hover {
      background-color: $teal-80;
      border-color: $teal-80;
    }
  }
}
