
.analyzer {
    #loader {
        position: relative;
        width: 300px;
        margin: 0 auto;
        text-align: center;
        min-height: 640px;
    }
    #loader img {
        position: absolute;
        top: 24px;
        left: 72px;
        -webkit-animation-name: fade;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-duration: 6s;
        animation-name: fade;
        animation-iteration-count: infinite;
        animation-duration: 6s;
    }

    @-webkit-keyframes fade {
        0% {opacity: 0;}
        20% {opacity: 1;}
        33% {opacity: 1;}
        53% {opacity: 0;}
        100% {opacity: 0;}
    }
    @keyframes fade {
        0% {opacity: 0;}
        20% {opacity: 1;}
        33% {opacity: 1;}
        53% {opacity: 0;}
        100% {opacity: 0;}
    }

    #l1 {
        animation-delay: 0s;
        -webkit-animation-delay: 0s;
    }
    #l2 {
        animation-delay: -4s;
        -webkit-animation-delay: -4s;
    }
    #l3 {
        animation-delay: -2s;
        -webkit-animation-delay: -2s;
    }
}