@import '../../../styles/scss/variables.scss';

.filter {
  .select-control-items {
    display: inline-flex;
    border-top: 1px solid #f2f2f2;
    padding: 12px 8px !important;
    width: 100%;
  }

  .option {
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 16px !important;
  }
}

.bill-action .show.dropdown-menu {
    overflow: visible !important;
  }

  .control-buttons {
    padding: 16px 15px;
    border-top: 1px solid #f2f2f2;

    .btn {
      font-size: 12px;
      min-width: auto;
      margin-right: 8px;
    }
    #select-control-clear-all {
      color: #eb5757;
    }
  }

  .bill-action-filter {
    padding: 24px 15px;
    width: 300px;
    align-items: center;

    .error {
      width: 100%;
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb !important;
      padding: 0.75rem 1.25rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      margin-top: 0.5rem;
    }

    h3 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;
    }

    .dropdown {
      .dropdown-menu {
        z-index: 100;
      }
    }

    .action-date-picker {

      .rdp-button:hover:not([disabled]),
      .rdp-button:active:not([disabled]) {
        background-color: $seagreen-10;
      }

      .rdp-day_selected:not([disabled]) {
        background-color: $seagreen-100 !important;
      }

      .rdp-button:focus:not([disabled]),
      .rdp-button:active:not([disabled]),
      .rdp-day_selected:focus:not([disabled]) {
        border: none;
      }
    }
  }