@import '../../styles/scss/variables.scss';

.demonstration-summary {
    width: 90%;
    margin: 60px auto;
    min-height: calc(100vh - 732px);

    .tab-links {
        display: flex;
        gap: 16px;

        @media (max-width: $sm) {
            font-size: 14px;
            gap: 14px;
        }
    }

    .tab-links a.selected {
        // border-bottom: 4px solid rgb(255, 132, 39);
    }

    .number-and-controls {
        margin-bottom: 24px;

        h2 {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            margin-top: 8px;

            span {
                font-weight: 700;
            }
        }

        .bill-metadata {
            display: flex;
            flex-direction: row;
            gap: 18px;
            align-items: center;
            flex-wrap: wrap;

            .bill-version {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-left: 12px;
            }
        }

        @media (max-width: $sm) {
            h2 {
                font-size: 20px;
                line-height: 30px;
            }

            .bill-metadata>.pill {
                font-size: 12px;
            }

            .bill-metadata {
                font-size: 14px;
            }
        }
    }

    .bill-title {
        margin-bottom: 24px;
    }

    .disclaimer {
        margin-top: 24px;
        margin-bottom: 24px;
        font-style: italic;
    }

    .ai-summary-content {
        white-space: pre-wrap;
    }

    .feedback {
        margin-top: 24px;
        border-radius: 8px;
        padding: 12px;
        font-style: italic;
        background-color: rgb(255, 231, 206);

        strong {
            font-style: italic;
        }

    }
}