@import '../variables.scss';


.react-modal.premium-promo-modal {
    background-color: $dark-teal;
    color: $white;
    font-weight: 200;
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;

    .pull-right {
        margin-left: auto;
        display: flex;
        flex-direction: row-reverse;

        .pill {
            font-family: 'Inter', sans-serif;
            color: $black;
            background-color: $golden-yellow;
            padding: 6px 16px;
            border-radius: 16px;
            text-transform: uppercase;
            font-size: 12px;
            transform: translate(-24px, 20px);
            font-weight: 400;
        }
    }

    .modal-container {
        width: 90%;
        margin: auto;

        .label-group {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
        }

        .header {
            font-weight: 400;
            font-size: 24px;
            font-family: "DM Sans", sans-serif;
            padding: 0;
        }

        p {
            margin: 0;
        }

        .premium-offerings {
            margin-bottom: 24px;


            .caption {
                font-weight: 400;
                text-transform: capitalize;
            }
        }

        .primary-action-btn {
            color: $white;
            background-color: $teal-90;
            width: 100%;
            border-radius: 8px;
            border: none;
            padding: 5px;
            margin: 15px 0 30px;
        }

        .primary-action-btn:hover {
            background-color: $teal-100;
            border-color: $teal-100;
        }

        .primary-action-btn:active:focus {
            background-color: $teal-100;
            border-color: $teal-100;
        }
    }
}