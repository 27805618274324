@import "../../../styles/scss/variables";

.dropdown {

  .dropdown-menu {
    flex-direction: column;
    border: solid 2px $gray-06;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #0000000D;
    padding: 0;
    font-size: 14px;

    &.multi-select {
      padding: 24px;
    }

    .section-header {
      padding: 8px 16px;
      color: $dark-grey-blue;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.show {

  &.dropdown-menu {
    display: flex;
    overflow: auto;
    z-index: 97;
  }

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    color: $gray-90;
    margin-right: auto !important;
    padding: 14px;
  }

  .description {
    padding: 0 14px 12px;
  }

  &.multi-select {

    .title,
    .description {
      padding: 0;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .checked {
    color: $gray-90;
    border-top: 1px solid #FBFBFB;
    background-color: #FBFBFB;

    &.text-input {
      background-color: $white;
    }
  }

  .dropdown-item {
    background-color: $white;
    display: flex;
    align-content: center;
    padding: 14px;
    min-width: 175px;
    border-top: 1px solid #F2F2F2;
    outline-offset: 1px;
    cursor: pointer;

    &.text-input {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .dropdown-item-icon {
      display: flex;
      width: 16px;
      height: 16px;
      margin: auto 12px auto auto;
      cursor: pointer;
      align-self: center;
    }

    .dropdown-item-text {
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.02em;
      font-size: 14px;
      width: 100%;
      cursor: pointer;
      padding: 0;
      margin: 0;
      color: $gray-90;
    }

    &.checked {
      background-color: #FBFBFB;
      border-top: 1px solid #FBFBFB;

      .dropdown-item-text {
        font-weight: 700;
        letter-spacing: 0.02em;
      }

    }

    &.fixed {
      position: sticky;
      bottom: -1px;
    }
  }

  .dropdown-multi-item {
    display: flex;
    padding: 8px 0;
    cursor: pointer;
    overflow: hidden; /* fix for Firefox */
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;

    &:hover>input {
      color: $white;
      background-color: $seagreen-100;
      border-color: $seagreen-100;
      cursor: inherit;
    }

    label {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: $gray-50;
      cursor: inherit;
    }

    &:first-child {
      padding-top: 24px;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.disabled-option {
      cursor: not-allowed;
    }

    &.disabled-option>input {
      background-color: $white;
      border-color: $gray-40;
    }

    &.disabled-option:hover>input {
      color: $gray-05;
      background-color: $gray-05;
      border-color: $gray-40;
    }

    &.disabled-option>label {
      color: $gray-40;
    }
  }

  .dropdown-divider {
    background: $gray-190;
    height: 1px;
    width: auto;
    margin: 24px -24px;
  }
}

.checked+.dropdown-item,
.dropdown-item:hover+.dropdown-item {
  border-top: 1px solid #FBFBFB;
}


.sort-by,
.view-dropdown,
.bulk-actions-dropdown {
  display: flex;
  float: right;
  white-space: nowrap;
  padding: 6px 0;
  font-size: 12px;

  @media (max-width: 992px) {
    float: left;
    margin: 6px 0 0 4px;
  }

  @media (max-width: 576px) {
    display: block;
  }

  .checked {
    path {
      fill: $gray-70;
    }
  }

  // Override built-in multilevel dropdown styles
  & .bulk-dropdown-button {
    background-color: unset !important;
    color: unset !important;
    border-color: #EAEAEA !important;
    border-radius: 6px !important;
  }

  & .bulk-dropdown-text {
    color: $gray-70;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
  }

  & .menu-item li:not(.submenu-item):hover,
  & .menu-item li:not(.submenu-item):active {
    background-color: #FBFBFB;
    color: $gray-90;
  }

  & .dropdown-item-text~div {
    min-width: 275px;
  }

  & .submenu-item {
    padding: 15px;
    min-width: 275px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: unset;
    white-space: normal;

    &:hover {
      background-color: unset;
      color: $gray-90;
    }
  }

  & .submenu-item>* {
    max-width: 100%;
  }

  & .right-icon {
    position: absolute;
    right: 15px;
  }

}

.view-dropdown {
  padding-top: 30px;

  [aria-disabled="true"] .dropdown-button {
    cursor: default;
    color: $gray-40;

    .dropdown-icon path {
      fill: $gray-40;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}

.filter-group>.tagnames .dropdown-multi-item:first-child {
  padding-top: 0px;
}