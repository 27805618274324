@import '../../../styles/scss/variables';

button,
div.badge {
  &.btn-action {
    color: $white;
    background-color: $teal-80;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 12px 30px;
    min-width: 125px;
    border: 1.5px solid $teal-80;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &:focus {
      box-shadow: unset;
    }

    &.auto-width {
      min-width: unset !important;
    }

    &.orange {
      color: $white;
      background-color: $civic-orange;
      border: $civic-orange;
    }

    &.grey {
      color: $dark-grey;
      background-color: $textLightBackground;

      &:hover {
        color: $dark-grey !important;
        background-color: $light-grey;
      }
    }

    &.red {
      color: $white;
      background-color: $error-red;
      border: $error-red;
    }

    &:focus {
      outline: 5px auto $light-grey;
    }

    &:hover {
      color: $white;
    }

    &:disabled {
      background-color: $medium-grey !important;
      border-color: $medium-grey !important;
    }
  }

  &.btn-no-styles {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    text-align: left;
  }

  &.btn-text {
    border: 0;
    color: $teal-80;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    padding: 0.5rem 1.25rem;
    background-color: transparent;
    white-space: nowrap;

    &.bold {
      font-weight: 600;
    }

    &.clear-selection {
      font-size: 14px;
      color: $error-red !important;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      color: $medium-grey;
      cursor: unset;
    }

    &.destructive {
      color: $medium-grey !important;
    }

    &.link-style {
      color: $sky-blue;
      text-decoration: underline;
      font-style: italic;
    }
  }

  &.btn-light {
    color: $black;
    background-color: $very-light-blue;
    border: 0;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
  }
  &.btn-light:disabled {
    cursor: unset;
  }

  &.btn-teal {
    background-color: $teal-green;
    font-weight: 700;
    color: $white;
  }
  &.btn-teal:disabled {
    background-color: $light-teal;
    color: $white;
    cursor: unset;
  }

  &.btn-white {
    color: $plural-orange;
    background-color: $white;
    border: 1px solid #ff8427;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }
  &.btn-white:disabled {
    cursor: unset;
  }
  &.btn-white:hover {
    color: $plural-orange;
    border-color: #cb6609;
  }

  &.btn-orange {
    color: $white;
    background-color: $plural-orange;
    cursor: pointer;
    font-weight: 500;
  }
  &.btn-orange:disabled {
    cursor: unset;
  }
  &.btn-orange:hover {
    color: $white;
  }

  &.toggle {
    background-color: $gray-06;
    border-radius: 100px;
    border: none;
    padding: 6px 12px;
    display: flex;
    color: $gray-70;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    align-items: center;

    .toggle-indicator {
      height: 11px;
      width: 11px;
      background-color: $orange-100;
      margin-right: 6px;
      border-radius: 50%;

      &.on,
      &.good {
        background-color: $seagreen-100;
      }
      &.warning {
        background-color: $orange-100;
      }
      &.error {
        background-color: $error-red;
      }
      &.tracking {
        background-color: $orange-100;
      }
      &.track {
        background-color: $gray-40;
      }
    }
  }

  &.tracking-toggle {
    background-color: $gray-06 !important;
  }

  &.bill-status-toggle {
    opacity: unset !important;
    display: inline-block;
    color: $textControl !important;
    width: 130px;
    font-size: 0.7rem;
    white-space: normal;
    word-wrap: break-word;
    padding-left: 5px !important;
    padding-right: 5px !important;

    &.introduced {
      background-color: $blue-10;
    }
    &.passed_upper,
    &.passed_lower,
    &.passed {
      background-color: $orange-10;
    }
    &.governor,
    &.law {
      background-color: $green-10;
    }
    &.referral-committee,
    &.reported-out-of-committee {
      background-color: $purple-50;
    }
    &.concurrence {
      background-color: $misty-teal;
    }
    &.withdrawal,
    &.executive-veto {
      background-color: $error-red;
    }
  }

  &.bill-status-toggle.compact {
    width: 110px;
  }

  &.vote-result-toggle {
    opacity: unset !important;
    display: inline-block;
    width: 100px;
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 7px;
    letter-spacing: 1px;
    text-align: center;
    height: 30px;
    color: white;

    span {
      display: inline-block;
    }

    &.pass {
      background-color: $misty-teal;

      &:hover {
        cursor: default;
      }
    }

    &.fail {
      background-color: $error-red;

      &:hover {
        cursor: default;
      }
    }
  }

  &.regulation-type-toggle {
    opacity: unset !important;
    display: inline-block;
    color: $textControl !important;
    width: 160px;
    padding: 8px;
    background-color: $gray-06;
    text-transform: uppercase;
    border-radius: 50px;
    letter-spacing: 1px;
    text-align: center;

    &.nonrulemaking {
      background-color: $hawkes-blue;
    }

    &.rulemaking {
      background-color: $apricot;
    }

    &.notice {
      background-color: $rose-quartz;
    }
  }
}

.dropdown-button {
  color: $gray-70;
  background-color: $white;
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  border: 1px solid #eaeaea;
  box-shadow: 0 4px 10px 0 #0000000d;
  border-radius: 6px;
  text-transform: none;
  text-align: left;
  padding: 12px;
  display: flex;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  cursor: pointer;
  min-width: 100px;

  &.disabled {
    background-color: $gray-06;
    color: $gray-40;
    box-shadow: none !important;
    cursor: unset;

    .dropdown-arrow {
      opacity: 40%;
    }
  }

  .dropdown-icon {
    margin: auto 12px auto auto;
    width: 16px;
    height: 16px;
    display: flex;
    path {
      fill: $gray-70;
    }
  }

  .dropdown-title {
    flex: 1;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-arrow {
    width: auto;
    align-self: center;
    padding-left: 8px;
  }

  .dropdown-count {
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    background-color: $mint;
    color: $seagreen-100;
    border-radius: 2px;
    padding: 4px 8px;
    align-self: center;
    margin: 0 -8px 0 12px;
  }

  .centered-text {
    display: flex;
    align-items: center;
  }
}

.show {
  .dropdown-button:focus {
    color: $black;
    background-color: $very-light-blue;
  }
}

.split-button-dropdown {
  &.title-style {
    .split-button {
      border-radius: 1.125rem 0 0 1.125rem;
      background-color: $very-light-blue;
    }

    .custom-toggle-dropdown {
      border-radius: 0 1.125rem 1.125rem 0;
      background-color: $very-light-blue;
    }
  }

  .split-button {
    color: $black;
    background-color: $light-grey;
    border-radius: 0.2rem 0 0 0.2rem;
    padding: 8px 6px 8px 12px;
    outline: none;
    box-shadow: none;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .custom-toggle-dropdown {
    border-radius: 0 0.2rem 0.2rem 0;
    padding: 0;
    background-color: $light-grey;

    &.disabled {
      opacity: 0.65;
    }

    .custom-toggle-dropdown-trigger {
      align-self: center;

      .dropdown-arrow {
        flex: 0;
        margin: 8px 12px 8px 6px;
      }
    }
  }
}

.custom-toggle-dropdown {
  cursor: pointer;
}

.scroll {
  opacity: 0.8;
  background-color: $sky-blue;
  width: 25px;
  height: 30px;
  position: fixed;
  right: 0;
  bottom: 75px;
  border: none;
  color: $white;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }
}

.hidden-link {
  color: unset;

  &:hover {
    color: unset;
    text-decoration: unset;
  }
}
