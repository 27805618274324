@import '../../../styles/scss/variables';

.form-popover-container {
  margin-left: -10px;
  margin-right: 10px;
  padding: 10px;
  z-index: 98;

  @media (max-width: 576px) {
    left: 33px !important;
  }

  .form-popover {
    padding: 24px;
    background: #fff;
    box-shadow: $menu-shadow;
    border-radius: 6px;

    .form-popover-label {
      color: $textPrimary;
      font-size: 1.25rem;
      font-weight: bold;
    }

    .done,
    .btn-secondary {
      background-color: $textLightBackground;
      color: $textPrimary;
      border: 0;
      cursor: pointer;
      font-size: 80%;
      font-weight: bold;
      text-transform: uppercase;
    }

    .done {
      float: right;
      padding: 0.25rem 1rem;
    }
  }
}

@media (max-width: 768px) {
  .form-popover-container {
    .form-popover {
      max-width: 88vw;
    }
  }
}

.popover-container {
  max-width: 20rem;
  overflow: inherit !important;
  z-index: 100;

  .custom-control-label {
    &::before {
      border: $black solid 1px;
    }
  }

  &.email-bill-popover {
    max-width: 21rem;
  }
}

.popover-content {
  z-index: 98;
  background: $white;
  box-shadow: 0px 0px 10px $shadow-black;
  border-radius: 0.2rem;
  opacity: 1;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;

  .popover-confirm-label {
    color: $black;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 24px;
    display: flex;
  }

  .team-selector {
    max-height: 30vh;
    overflow-y: auto !important;
  }

  .tag-selector {
    max-height: 26vh;
    overflow-y: auto !important;
  }

  .popover-confirm-body {
    font-size: 14px;
  }

  .upgrade-promo {
    font-size: 12px;
  }

  .track-count-ribbon {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    bottom: 0;
    left: 0;
    background-color: $orange-10;
    padding: 10px 0px;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }

  p {
    margin-bottom: 0;
  }
}