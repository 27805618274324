@import "../../../styles/scss/variables.scss";

.nav-container {
  color: $offBlack2019;
  background-color: #ffffff;
  flex-basis: 0px;
  flex-grow: 1;
  z-index: 3;

  .nav {
    margin-bottom: 20px;
  }

  .nav-tab {
    margin: 0 40px;

    @media (max-width: 414px) {
      margin: 0 12px;
    }
  }

  .nav-pills {
    z-index: 1;
    box-shadow: 0px 1px 0px #F3F3F3;
    padding: 0 20px;

    .nav-link {
      padding: 1rem 1rem 0.5rem 1rem;
      background: #fff;
      border-radius: 0;
      border: 1px solid $very-light-blue;
      color: $black;
      font-size: 0.8rem;
      text-align: left;
      font-weight: 600;
    }

    .nav-link.active {
      color: $black;
      background-color: $very-light-blue;
    }


    .nav-link-flag {
      flex: none;
      padding: 18px 0;
      margin: 5px 20px 0;
      background: none;
      border-radius: 0;
      color: $medium-grey;
      font-size: 0.8rem;
      text-align: left;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
      }
    }

    .nav-link-flag.active {
      color: $sky-blue;
      border-bottom: 2px solid $sky-blue;
    }
  }

  @media (max-width: 768px) {
    .nav-pills {
      z-index: 1;

      .nav-text {
        display: block;
      }
    }
  }
}

