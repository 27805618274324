@import '../../../styles/scss/variables';

.tooltip-gate {
  background: $light-mint !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  z-index: 100000 !important;
  padding: 16px !important;
  opacity: 0.95 !important;
  max-width: 400px;
  overflow-wrap: break-word;
  white-space: normal;
  text-transform: none;

  .tooltip-gate-content {
    display: flex;
    align-items: center;

    .tooltip-gate-text {
      color: $deep-green;
      margin: auto;
      margin-left: 8px;
      padding: 0 !important;
      text-align: left;

      .tooltip-header {
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 2px;
        padding: 0px;
        display: block;
      }
    }

    a {
      color: $plural-orange !important;
    }

    svg {
      padding: 0 !important;
      min-width: 10%;
    }
  }
}
