@import "../../styles/scss/variables.scss";

.notes {
  color: $dark-grey-blue;
  padding: 1rem;
  height: 1.5rem;
}
.not-login-container {

  .not-login-header {
    height: 1.5rem;
    font-size: 1.125rem;
  }

  .controls {
    margin: 1.5rem;
    height: 40px;
    .view-upgrade-option {
      margin-bottom: 1rem;
      background-color: $primary;
    }
    .back-to-team {
      background-color: $highlight;
    }
  }
}
