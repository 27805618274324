@import '../../styles/scss/variables.scss';

.jurisdictions-view {
  .header-top {
    margin-bottom: 32px;
  }

  .header-top .title {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
  }

  path,
  circle {
    cursor: pointer;
  }

  path.selected,
  circle.selected {
    stroke: #27a4cc;
    stroke-width: 2;
  }

  .nav-container .nav-pills .nav-link-flag {
    padding-bottom: 10px;
  }

  .addtl-jurisdictions-container {
    padding: 10px 40px;

    div {
      margin-top: 16px;
    }

    button {
      width: 260px;
    }
  }

  .list .list-header {
    border: 0;
  }

  .resources-tab p {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
  }

  a.reference-link {
    display: block;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: $offBlack2019;

    div.link-wrapper {
      padding: 24px;

      span {
        font-size: 16px;
        font-weight: 600;
      }

      strong {
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin-top: 8px;
      }

      p {
        margin-top: 8px;
      }
    }

    div.view-more {
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      color: #26a4cc;
    }
  }

  a.reference-link:hover {
    text-decoration: none;
  }

  hr {
    margin: 24px -40px;
  }

  .filters {
    margin: 24px 0;
  }

  .committee-grid {
    padding: 0 0 24px 0;
  }
}

#explorer-popup-container {
  width: 264px;
  min-height: 100px;
  background-color: rgb(251, 251, 251);
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px #0000000d;

  h3 {
    font-size: 16px;
    font-weight: 700;
  }

  h4 {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 700;
  }

  a {
    color: #27a4cc;
  }
}

#explorer-popup-container.right {
  &:before {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    top: 20px;
    left: -19px; /* 1px buffer for zooming problems while rendering*/
    border-width: 10px;
    border-color: transparent rgb(251, 251, 251) transparent transparent;
    border-style: solid;
  }
}

#explorer-popup-container.left {
  &:before {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    top: 20px;
    right: -19px; /* 1px buffer for zooming problems while rendering*/
    border-width: 10px;
    border-color: transparent transparent transparent rgb(251, 251, 251);
    border-style: solid;
  }
}

.jurisdictions-sessions-table-wrapper {
  display: flex;
  justify-content: center;
  min-height: 300px;
  position: relative;

  .jurisdiction-sessions-table {
    width: 75%;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

}

.jurisdiction-page-wrapper {
  font-family: 'DM Sans';
  overflow-x: hidden;

  .breadcrumb {
    padding: 20px 0 2px 50px;
  }

  .jurisdiction-page-dropdown {
    margin-left: auto;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .trapezoidal-banner {
      border-bottom: 50px solid $teal-green;
      border-left: 40px solid transparent;
      display: flex;
      height: 50px;
      width: 450px;
    }

    h5 {
      padding-top: 14px;
      color: $light-gray;
      font-weight: 700;
      font-size: 1rem;
    }

    .jursidiction-select {
      width: 280px;
      margin-left: 20px;
      margin-top: 5px;
    }
  }

  .jurisdiction-overview-card {
    background-color: $light-gray !important;
    border-top: 2px solid $grey-10;
    margin-top: -6px;

    h1 {
      color: $teal-green;
      font-size: 2.3rem;
      padding: 25px 0 10px 40px;
    }

    .jurisdiction-map-info {
      display: flex;

      .mapboxgl-map {
        margin: 15px 0 40px 40px;
        width: 50%;
        height: 400px;
        border-radius: 8px;
      }

      h6 {
        font-size: 1.1rem;
        font-weight: 700;
        flex-grow: 0.05;
        padding-bottom: 5px;

        &.resource-title {
          padding-top: 15px;
        }
      }

      .overview-info {
        padding: 30px 0 0 70px;

        .reference-link {
          padding-bottom: 10px;
        }

        .available-count {
          display: flex;

         > a {
            padding-left: 5px;
          }
        }
      }
    }
  }

  .jurisdiction-tab-heading {
    margin: 32px 0 0 32px;
  }

  .committee-header {
    padding: 0px;

    .stat-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 24px 32px;
    }
  }
}

.table {
  position: relative;
  .rdt_Table {

    td,
    .rdt_TableBody {
      color: #1c2b4b;
      border-bottom: 1px solid #e5e5e5;

      span.pill {
        display: inline-block;
        padding: 7px 20px;
        background-color: #cbe9ff;
        border-radius: 100px;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        width: 120px;
      }

      span.pill.upper {
        background-color: #ffc187;
      }

      span.pill.legislature {
        background-color: #d5fbdb;
      }

      a {
        color: #27a4cc;
        font-weight: 400;
        font-size: 16px;
      }
    }

    tr.committee-header {
      background-color: #fbfbfb;
      color: #9b9b9b;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .rdt_TableCell {
      font-size: 0.9rem;
    }

    .table-link-container {
      display: flex;
      flex-direction: column;

      a {
        padding-top: 3px;
      }
    }

    .rdt_TableHead {
      font-size: 12px;
      color: $textControl;
      padding-bottom: 16px;
      border-bottom: solid 1px $background-grey;
      margin: 0;
      text-transform: uppercase;

      > div:first-child{
        padding-left: 10px;
      }
    }
  }

  .rdt_Pagination {
    position: absolute;
    top: -1px;
  }
}


.table.hearings-table {
  .rdt_Pagination {
    position: absolute;
    top: -1px;
  }

  #column-location {
    cursor: default;
  }

  .rdt_TableRow {
    & .rdt_TableCell {
      font-size: 14px;

      .committee-link-container {
        & a:not(:last-child)::after {
          content: ", ";
        }
      }
    }

    // Don't custom wrap button or link columns
    .rdt_TableCell:not(:has(button)):not(:has(a)), .truncate-box {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.hearing-status-toggle {
  opacity: 1 !important;
  min-width: 115px;
  display: flex;
  justify-content: center;

  &.past {
    color: $textPrimary;
    background-color: $light-grey;
  }

  &.upcoming {
    color: $textPrimary;
    background-color: $purple-50;
    padding: 8px 12px;
  }
}