@import '../../../styles/scss/variables.scss';

.custom-icon {
  border-radius: 50%;
  text-align: center;
  border: 2px solid;
  font-weight: 600;

  &.user {
    color: $seagreen-100;
    border-color: $seagreen-100;
  }

  &.file {
    color: $teal-80;
    border-color: $teal-80;

    .attachment-icon {
      transform: rotate(-90deg) scale(0.75);
    }
  }

  &.event {
    color: $butterscotch;
    border-color: $butterscotch;
  }
}

.switch-icon {
  cursor: pointer;
}

.switch-icon .switch-description {
  align-self: center;
  padding-left: 12px;
}

.additional-explanation {
  font-size: 12px;
  color: #666666;
  padding: 12px 0 0 34px;
}

.momentum-indicator {
  padding: 8px 16px;
}

.identical-bills {
  padding: 0;
}

.momentum-tooltip-content {
  width: 300px;
  padding: 8px 20px 8px 8px !important;
  font-weight: 500;
  text-align: start;
  font-size: 13px;

  p {
    padding: 0;
    margin-bottom: 0.5rem !important;
  }

  .btn-text, a {
    font-weight: 500 !important;
    color: $white !important;
    text-decoration: underline;
  }
}

.momentum-tooltip {
  z-index: 1;
}
