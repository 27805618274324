@import "../../../styles/scss/variables";

.bill-tag-button {
  cursor: pointer;
  align-items: center;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.popover-confirm-content {
  .label-text {
    cursor: pointer;
    color: $black !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  .label-workspace {
    font-style: italic;
  }

  .tag-selector {
    font-size: 14px;
  }
}

.tag-indicator {
  align-items: center;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    .tag-indicator-close {
      visibility: visible;
    }
  }

  .tag-indicator-label {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  .tag-indicator-close {
    margin-left: 4px;
    margin-right: 12px;
    height: 8px;
    width: 8px;
    align-self: center;
    visibility: hidden;
  }
}