@import "../../../styles/scss/variables.scss";

.layout-takeover {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 95;
  }

  .fullscreen-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .default-background {
    background-image: url("../../../static/bill-blur-bg.png");
  }

  .fullscreen-modal-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 96;

    > div {
      text-align: center;
    }

    .overlay-icon > svg {
      width: 50px;
      height: 80px;
    }

    .overlay-header {
      font-weight: 700;
      font-size: 28px;
      color: $teal-green;
      padding: 10px 0;
    }

    .overlay-body {
      font-weight: 400;
      font-size: 16px;
      width: 75%;
      margin: 0 auto;
      padding: 10px 0 30px 0;
    }

    .overlay-button-group {
      padding-bottom: 20px;

      button {
        margin: 0 10px;
        font-size: 12px;
      }

      .demo-button {
        padding: 12px 22px;
      }
    }
}

.blur-element {
  filter: blur(8px) grayscale(50%);
}
