@import '../../../styles/scss/variables.scss';

.bill-search-container,
.search-container {
  width: 100%;
  min-height: 1200px;

  .filters-container {
    padding-right: 32px;
    padding-left: 36px;
    padding-top: 8px;
    border-bottom: 1px solid $gray-190;
  }

  .saved-search-controls {
    &.container {
      display: flex;
    }

    margin: 0 auto;
    padding: 35px 17px 12px 17px;

    h2 {
      display: inline-block;
    }

    button {
      font-size: 12px;
      font-weight: 600;
      color: #5f5f5f;
      border-color: #e1e1e1;
      margin-right: 16px;
      margin-bottom: 8px;
    }

    button:hover {
      background-color: #e1e1e1;
    }

    h2 {
      font-size: 22px;
      font-weight: 700;
    }

    .saved-search-bell-shared {
      padding-top: 5px;
    }
  }
}

.bill-search-container.sponsored-bills {
  width: 100%;
  min-height: 1000px;
  max-width: 1080px;

  .bill-table.filtered-table {
    margin-left: -80px;
  }

  .result-dropdown-container {
    padding-top: 2px;
    margin-left: 100px;
    display: flex;
    flex-direction: unset;
  }

  .total-count {
    flex-basis: 30%;
    display: flex;
  }

  @media screen and (max-width: 1700px) {
    .bill-table.filtered-table {
      margin-left: 0px;
    }

    .result-dropdown-container {
      margin-left: 0px;
    }

    .dropdown-container {
      padding-left: unset;
      padding-right: 20px;
      padding-top: 2px;
    }

    .total-count {
      padding-left: unset;
    }

    .bill-table-header .total-count {
      padding-left: unset;
    }
  }
}

.bill-search,
.regulation-search,
.default-search {
  padding: 1rem 2rem;
  background-color: white;

  .bill-search-filters,
  .regulation-search-filters,
  .default-search-filters {
    display: inline-flex;
    padding: 0;
    background-color: $white;
    border: 1px solid $gray-05;
    border-radius: 10px;
    margin-right: 4px;

    .keyword-search-field {
      border-radius: 10px;
      font-size: 14px;

      .search-icon {
        margin: 4px 8px;
      }

      input {
        border: none;
        background: none;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }

    .keyword-search-field,
    .jurisdiction-dropdown {
      width: 50%;
      padding: 4px 20px;
      line-height: 30px;

      input[type='text'] {
        min-width: 100px;
      }
    }

    .vert-line-break {
      border-left: 2px solid $gray-190;
      height: 2rem;
      margin: auto 0;
    }

    @media (max-width: 992px) {
      display: block;
      background: none;

      .keyword-search-field,
      .jurisdiction-dropdown {
        width: 100%;
        margin: 12px 0;
      }

      .vert-line-break {
        display: none;
      }
    }

    @media (max-width: 576px) {
      .selected-filters {
        display: none;
      }
    }
  }

  .bill-search-buttons,
  .regulation-search-buttons,
  .default-search-buttons {
    padding: 0 3px 0 0;

    @media (max-width: 992px) {
      margin-left: auto;
      margin-top: 12px;
    }

    button {
      margin: 0 4px;
    }

    #search-button {
      background-color: $teal-80 !important;
      color: white;
      font-weight: 500;
      border: 1px solid $teal-80;
      padding: 10px 12px 11px;
    }

    #adv-search-btn {
      border: 1px solid #efefef;
      padding: 8px 12px 12px;

      &.active,
      :active {
        background-color: $teal-80 !important;
        border-color: $teal-80 !important;
      }

      &:hover {
        background-color: $white;
      }
    }
  }

  .adv-search-filters {
    padding: 15px 0 6px;

    .adv-filter {
      display: flex;
      align-items: center;
      align-self: center;
      color: $gray-70;
      font-size: 14px;
      margin-right: 12px;
      margin-bottom: 10px;

      .dropdown-button {
        box-shadow: none;
      }

      #momentum-bills-tooltip {
        max-width: 300px;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      #bill-topics-tooltip-detail {
        margin-left: 5px;
      }

      .switch-icon {
        margin-right: 8px;
      }

      @media (max-width: 992px) {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    #clear-adv-btn {
      color: $error-red;
      font-size: 14px;
      font-weight: 500;

      .close-icon {
        width: 12px;
        margin: 0 4px;
      }

      span {
        margin: 4px;
        vertical-align: middle;
      }
    }

    .adv-search-selector {
      .options {
        max-height: 50vh;
        overflow-y: auto;

        .option {
          border-bottom: 1px solid $gray-06;
          padding: 8px 16px !important;

          &:hover {
            background-color: $gray-06;
          }

          .checkbox {
            border: 1px solid $gray-50;

            &:checked {
              border: 1px solid $seagreen-100;
            }
          }

          .option-label {
            width: 100%;
          }
        }
      }
    }

    .select-control-items,
    .control-buttons {
      display: inline-flex;
      border-top: 1px solid $gray-06;
      padding: 12px 8px !important;
      width: 100%;

      #select-control-clear-all {
        color: $error-red !important;
      }
    }

    .control-buttons {
      padding: 16px 15px !important;

      .btn {
        font-size: 12px;
        min-width: auto;
        margin-right: 8px;
      }
    }
  }
}

.table.bill-table {

  & .bill-link {
    color: $textControl;
    font-size: 14px;
  }

  .rdt_Pagination {
    position: absolute;
    top: -10px;
  }

  .rdt_TableRow {
    & .rdt_TableCell {
      font-size: 14px;
    }

    // Don't custom wrap button or link columns
    .rdt_TableCell:not(:has(input[type='checkbox'])):not(:has(button)):not( :has(.bill-tag-button)):not(:has(a)) {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.table.bill-table.filtered-table {
  width: 135%;
  margin-left: -195px;
  border-bottom: unset;

  .total-count {
    font-style: italic;
    font-size: 14px;
    padding: 2px 15px 6px 0;
    color: $medium-grey;

    &>div {
      display: inline-block;
    }

    span {
      padding-right: 12px;
      padding-top: 55px;
    }

    div:has(.dropdown) {
      font-style: normal;
    }
  }

  .jurisdiction-filter.result-total,
  .filtered-group.result-total {
    padding: 10px 5px 5px 18.5px;
    float: left;
    display: inline-flex;
  }

  .bulk-actions-dropdown {
    padding-top: 2px;
  }
}

@media only screen and (max-width: 1560px) {

  // Small screen styling should always be used if applicable
  .table.bill-table {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1760px) {
  .table.bill-table.filtered-table {
    width: 100%;
    margin-left: 0;
  }
}

#bill-search-results-table {
  width: 120%;
  margin-left: -100px;
  position: relative;
}

.table-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
}

.date-pickers {
  margin-right: 0;
  margin-left: 0;

  .dropdown {
    width: 122px;
  }

  .dropdown-title {
    margin-left: 6px;
  }

  .dropdown-arrow {
    display: none;
  }

  .dropdown-button {
    padding: 3px;

    .dropdown-icon {
      margin: 6px 3px;
    }
  }

  .divider {
    width: 10px;
    border-bottom: 1px solid black;
    margin: 20px 8px;
  }
}

.end-of-session-report-container {
  background: $gray-06;
  border-radius: 10px;
  padding: 0.5rem;
  margin-top: 10px;
  margin-bottom: 15px;

  .hideable-content {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    color: black;

    .title {
      cursor: pointer;
      .sparkle {
        transform: translate(0px, 2px);
      }
    }
  }

  .react-sanfona-item {
    margin-top: 10px !important;
  }

  .react-sanfona-item-expanded {
    .arrow-icon {
      transform: rotate(90deg);
      display: inline-block;
    }
  }

  .formatted-report {
    margin: 0px 13px 0px 13px;

    .context {
      font-size: 0.9rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .report {
      font-size: 10pt;
    }

    .error-message {
      font-size: 10pt !important;
    }
  }

  .text-field-spinner {
    align-self: center;
    display: block;
    margin: auto;
  }
}

p.eosr-explainer-text {
  font-size: 14px;
  padding: 0 20px;
}