@import '../../../styles/scss/variables.scss';

.value-badge.xs {
    font-size: 9px;
    padding: 2px 7px;
    border-radius: 9px;
    height: 14px;
}

.value-badge.sm {
    font-size: 12px;
    padding: 3px 7px;
}

.value-badge.md {
    font-size: 14px;
    padding: 5px 12px;
}

.value-badge.lg {
    font-size: 16px;
    padding: 7px 16px;
}