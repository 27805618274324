@import '../../styles/scss/variables.scss';

.committee-view {
  .source-link {
    color: $teal-80;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    cursor: pointer;
  }

  .committee-header {
    padding: 38px 32px;
    box-shadow: 0 1px 0 0 #e5e5e5;
  }

  .stat {
    border-radius: 6px;
    border: 1px solid #eaeaea;
    padding: 14px 14px;
    min-width: 140px;
    margin: 32px 5px 0;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .stat-number {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }

  .stat-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }

  .filters {
    margin: 32px;
    .filter {
      margin-right: 8px;
    }
  }

  .committee-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
    padding: 0 32px 38px;
  }

  .staff-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
  }
}
