@import '../../../styles/scss/variables';

.multi-page-form {
  .title {
    color: $black;
    font-size: 24px;
  }

  .subtitle {
    color: $dark-grey-blue;
    font-size: 14px;
    margin: 16px 0;
  }

  .footer {
    background-color: $very-light-blue;
    padding: 24px;
    margin-top: 16px !important;
  }
}

.react-modal .multi-page-form .footer {
  margin: -20px;
}

.multi-select-form {
  width: 100%;
  height: 100%;

  .select-control {
    margin: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .section-container {
    display: flex;
    gap: 16px;
    width: 100%;
  }

  .section-column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .section-options {
    padding-right: 8px;
    flex-grow: 1;
  }

  .section-header {
    padding: 8px 16px;
    color: $dark-grey-blue;
    font-weight: bold;
    font-size: 14px;
  }

  .description {
    font-size: 14px;
    color: $dark-grey-blue;
    font-weight: bold;
  }

  .options {
    column-width: 150px;
    -webkit-column-width: 150px;
    -moz-column-width: 150px;
  }

  .option {
    padding: 4px 0 4px 16px;
    display: flex;
    box-sizing: border-box;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;

    .option-label {
      display: flex;
      margin: 4px;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.form-group {
  font-size: 14px;

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $very-light-blue;
  }
}

.form {
  .row {
    // resetting bootstrap styles
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .emphasis-text {
    font-weight: 600;
    color: $gray-90;
  }

  .field-header {
    margin: 20px 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: $gray-90;

    &:first-child {
      margin-top: 0;
    }
  }

  .field-group {
    display: inline-flex;
    width: 100%;
    padding: 8px 0;

    &:hover {
      .hover-form-field-action {
        visibility: visible;
      }
    }

    label {
      color: $gray-90;
      font-weight: 600;
      margin-bottom: 0;
    }

    .form-field {
      flex-basis: 0;
      flex-grow: 1;
      margin-left: 0;
      margin-right: 0;
      box-shadow: unset;
    }

    .form-field-action {
      flex-grow: 0;
      margin: 10px;
      align-self: center;
    }

    .hover-form-field-action {
      visibility: hidden;
      flex-grow: 0;
      margin: 10px;
      align-self: center;
    }
  }

  .option-description {
    padding: 8px 0;
  }

  .form-field {
    margin: 0;
    font-size: 14px !important;
    min-width: 275px;
    background-color: $gray-06;
    border: none;
    border-radius: 6px;

    @media (max-width: 576px) {
      min-width: auto;
    }

    &.text-field {
      background-color: $gray-06;
      border: none;
      width: unset;
    }

    &::placeholder {
      font-style: italic;
    }

    a {
      color: $dark-grey-blue;
      text-decoration: underline;
    }
  }

  .dropdown-button {
    margin: 16px 0;
    width: 100%;
    font-size: 0.875rem !important;
    padding: 0.375rem 0.75rem;
    color: #7a7a7a;
    border: 1px solid #ced4da;
  }

  .checkbox {
    margin: 10px 0 !important;

    span {
      font-size: 0.75rem;
    }
  }

  .required {
    color: $civic-orange;
  }

  .error {
    font-size: 0.875rem;
  }

  .submit>button {
    margin: 20px 0;
  }
}

.emails-form {
  .emails-form-buttons {
    margin-left: -5px;
    margin-right: -5px;

    * {
      margin: 5px;
    }
  }
}

.callout {
  background-color: #e5e9ed;
  padding: 1rem;
  margin-bottom: 1rem;

  h3 {
    font-weight: 600;
  }
}

.notif-settings {
  .notif-top-description {
    margin-bottom: 40px;

    .notif-top-title {
      font-size: 28px;
    }
  }

  .notif-title {
    font-size: 16px;
    font-weight: 600 !important;
  }

  .notif-option-title {
    font-weight: 500 !important;
  }
}