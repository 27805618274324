@import '../../../styles/scss/variables.scss';

.person-staff, .committee-staff {
    padding: 20px 20px 0 20px;

    h2 {
      padding-bottom: 10px;
      font-size: 1.3rem;
    }

    .filters-container {
      padding: 0px 3px 30px 15px;
      border-bottom: unset;
    }

    .staff-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 20px;
    }

    .grid-card {
      .overflow-menu {
        float: right;
        padding-right: 15px;
      }

      .custom-toggle-dropdown {
        float: right;
      }

      .custom-toggle-dropdown-trigger {
        width: 24px;
        height: 24px;
        text-align: center;
        color: $medium-grey;
        cursor: pointer;
      }

      .mr-auto {
        padding-top: 20px;
      }
    }
  }