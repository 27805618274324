@import '../../styles/scss/variables.scss';

.regs-filters {
  padding: 16px 0 0 0;
  margin-left: -12px;
  display: flex;

  @media (max-width: 768px) {
    padding: 16px 0 0 0;
    width: 100%;
  }

  .filter {
    flex-grow: 0.3;
  }

  .dropdown-menu {
    max-height: calc(50vh);
  }
}

.regulation-view {
  // display: flex;
  font-family: 'DM Sans';
  background-color: white;

  .filter {
    padding: 16px 12px 0 0;

    @media (max-width: 768px) {
      padding: 16px 0 0 0;
      width: 100%;
    }
    & .dropdown-button {
      border: 0;
    }

    .dropdown-menu {
      max-height: calc(50vh);
    }
  }

  .main-pane {
    display: flex;
    padding: 0;
    height: 100%;

    .header {
      padding: 1rem;
      height: auto;
      position: sticky;
      margin-left: 0.563rem;
      border-top: solid #f3f3f3 0.063rem;
      border-bottom: solid #f3f3f3 0.063rem;

      .regulation-type {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: bold;
        color: $teal-green;
        margin-top: 1%;
        margin-bottom: 1%;

        span {
          margin-left: 0.313rem;
        }
      }

      .regulation-title {
        font-size: 1.5rem;
        font-weight: bold;
        color: $deep-green;
        margin-bottom: 1%;
      }

      .agency-created-by {
        font-size: 1rem;
        color: black;

        .agency-name {
          font-weight: bold;
        }
      }
    }

    .docket-tabs {
      margin-top: 0;
      border: 0 !important;

      .nav-link.active {
        color: $black;
        background-color: $plural-orange;
      }

      .nav-link-flag.active {
        color: $plural-orange;
        border-bottom: 0.125rem solid $plural-orange;
      }

      .nav {
        flex: 0 0 100%;
        background-color: $grey-05;
        flex-wrap: wrap;
        margin-left: -0.938rem;
        margin-bottom: 0rem;

        .docket-details-tab {
          text-transform: capitalize;
          font-size: 0.875rem;
          span {
            margin-left: 0.313rem;
          }
        }

        .browse-documents-tab {
          span {
            margin-left: 0.188rem;
          }
        }

        .browse-comments-tab {
          span {
            margin-left: 0.188rem;
          }
        }
      }

      .col-12 {
        display: flex;
        flex: 1;
        border-top: solid #f3f3f3 0.063rem;

        .docket-details {
          margin-left: 2.375rem;
          margin-top: 1.1rem;
          font-size: 1rem;

          .docket-details-title {
            font-size: 1.125rem;
            font-weight: bold;
            margin-bottom: 0.938rem;

            span {
              margin-left: 0.313rem;
            }
          }

          .sidebar-title {
            font-weight: bold;
            margin-bottom: 0.313rem;
          }

          .sidebar-text {
            margin-bottom: 0.625rem;
          }
        }
        .row {
          justify-content: space-between;

          @media (max-width: 992px) {
            flex-direction: column;
          }

          .nav-pills {
            background-color: white;
            order: 5;
            padding-left: 10%;
          }

          .sidebar {
            padding: 0;
            margin: 0 !important;
            height: 100%;
            overflow: auto;
            border-left: solid #f3f3f3 0.125rem;
          }
          .bill-sidebar-content {
            flex: 1;
            .nav-container {
              .nav {
                margin-left: -2.625rem;
                @media (max-width: 992px) {
                  margin-left: -10%;
                }
              }
            }
            .docket-abstract-title-block {
              .docket-abstract-title {
                background-color: $grey-10;
                color: $gray-70;
                padding-left: 1.625rem;
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
              }
            }

            .docket-abstract-content {
              font-size: 1rem;
              padding-left: 1.625rem;
              padding-top: 1.2rem;
              padding-bottom: 0.438rem;
              padding-right: 1.625rem;
            }

            .no-docket-abstract {
              padding-top: 15%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
