@media print {
    .bill-meta {
        display: none;
    }

    .primary-layout-wrapper {
        margin-left: 0px;
    }

    #primary-sidebar {
        display: none;
    }

    .dropdown-menu.show {
        display: none;
    }
}
