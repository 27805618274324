@import "../../../styles/scss/variables";

.custom-textbox {
  color: $black;
  font-size: 12px;
  padding: 6px 8px;
  margin-bottom: 1rem;
  border: 1px solid $medium-grey;
  border-radius: 0.25rem;

  &::-webkit-input-placeholder {
    color: $medium-grey;
    font-style: italic;
  }

  &:-moz-placeholder {
    color: $medium-grey;
    font-style: italic;
  }

  &:-ms-input-placeholder {
    color: $medium-grey;
    font-style: italic;
  }
}

.borderless-textbox {
  font-size: 14px;
  border: none;
  margin-bottom: 0;
  padding: 0px;

  &:focus {
    outline: none;
  }
}

.copy-textbox {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: $white;

  .copy-icon {
    align-self: center;
    padding: 0 8px;
  }

  #copy-text {
    align-self: center;
    padding-left: 8px;
    font-size: 14px;
    color: $black;
  }

  &.disabled {
    background-color: $background-grey;

    #copy-text {
      color: $medium-grey
    }
  }
}
