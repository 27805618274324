@import '../../../../styles/scss/variables';

/* .toolbar {
  position: relative;
  margin-top: -32px;
} */

.mentions-dropdown {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 10000;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  width: 278px;
  overflow: hidden;

  .menu-item {
    display: flex;
    padding: 8px 10px;

    &:hover {
      background-color: $gray-06;
      overflow: hidden;
    }

    .user-icon {
      align-self: center;
    }

    .mention-name {
      margin: 0 8px;
      font-size: 0.875rem;
    }
    .mention-email {
      margin: 0 8px;
      font-size: 0.75rem;
    }
  }
}

span.rich-text-mention {
  padding: 3px;
  margin: 0 1px;
  display: inline-block;
  border-radius: 6px;
  background-color: #eee;

  &.selected {
    box-shadow: 0 0 0 2px #b4d5ff;
  }

  &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }

  &.highlight {
    background-color: $light-teal !important;
    color: $teal-green !important;
  }
}

.rich-text-editor {
  border: 1px solid $gray-30;
  border-radius: 6px;
  margin-bottom: 10px;
  position: relative;
  background-color: $white;

  .toolbar {
    width: 100%;
    border-bottom: 1px solid $gray-30;
    padding: 8px;
    font-size: 1rem;
  }

  .rich-text-button {
    border: none;
    background: none;
    margin: 2px 4px;

    .bar {
      width: 36px;
    }

    &:hover {
      background: $gray-05;
      border-radius: 4px;
    }

    &.active {
      background: $gray-10;
      border-radius: 4px;
    }

    &:disabled path {
      fill: $gray-40;
    }
  }

  input:disabled + label path {
    fill: $gray-40;
  }

  .vertical-bar {
    font-size: 20px;
    color: rgb(189, 189, 189);
    margin: 0px 2px;

    &::after {
      content: '|';
    }
  }

  .post-button-row {
    justify-content: flex-end;
    margin: 0 24px 24px 0;
  }

  .visibility {
    align-self: flex-end;
    margin-right: auto;

    label {
      width: 79px;
      font-size: 0.875rem;
      line-height: 1rem;
      color: $sky-blue;
      margin: 0;
    }

    &:hover {
      label {
        color: $sky-blue;
        cursor: pointer;
      }
    }
  }

  .user-file-attachment {
    position: relative;
    font: 20px/1 'Open Sans', sans-serif;
    width: 24px;
    height: 24px;
    padding: 4px;
    border: 2px solid $jade;
    border-radius: 100%;
    left: 10%;
    top: 10%;
    background-color: $white;
    transform: rotate(-90deg);
    cursor: pointer;
  }

  .visibility,
  .user-file-attachment {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
}

.summary {
  font-size: 12px;
  font-weight: 500;
  color: $teal-80;
  position: absolute;
  left: 15px;
  bottom: 30px;
}

#bill-summary-tooltip {
  position: absolute;
  left: 144px;
  bottom: 32px;
}

.spinner {
  position: absolute;
  left: 170px;
  bottom: 32px;
  width: 20px;
}
