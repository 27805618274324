@import '../../../styles/scss/variables';

.form-inner-class {
  width: 50vw;
  height: 70vh;
  overflow: scroll !important;
}

input.date-input {
  background: url('../../../static/icons/calendar.svg') no-repeat 5px center;
  background-size: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  width: 122px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
}

.jurisdiction-selector {
  .multi-select-form {
    margin-bottom: 16px;

    .select-control {
      padding-left: 0 !important;
    }

    .option {
      padding: 0 10px 0 0 !important;
    }

    .option-label {
      min-width: 130px;
      color: $dark-grey-blue;
    }
  }
}

form.jurisdiction-selector {
  .options {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;

    .form-check-label {
      display: unset;
    }

    h4 {
      font-size: 1rem;
    }
  }
}

.error:not(.toggle-indicator) {
  width: 100%;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb !important;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.jurisdiction-dropdown {
  border-radius: 10px;
  font-size: 0.875rem;

  svg {
    margin: 7px 5px;
  }

  input {
    border: none;
    background: none;

    &:focus {
      outline: none;
    }
  }

  .btn-text {
    padding: 0.5rem;
  }

  .location-icon {
    margin: 0 4px;
    padding-top: 4px;
  }

  .selected-filters {
    display: inline-flex;
    justify-content: flex-end;
    overflow: hidden;
    margin-right: 8px;
    padding-top: 2px;

    button {
      display: flex;
      white-space: nowrap;
      margin: auto 4px;
      padding: 4px 7px 0px 12px;
      color: $teal-100;
      background-color: #e8f6fb;
      border-radius: 52px;

      .badge {
        color: $teal-50;
        padding: 0;
        top: -2px;
      }
    }
  }

  .dropdown {
    flex-basis: auto;
    margin-left: auto;

    .dropdown-menu {
      margin: 8px -4px 0 0;
    }

    .dropdown-count {
      font-weight: bold;
      letter-spacing: 0.02em;
      background-color: $mint;
      color: $seagreen-100;
      border-radius: 2px;
      padding: 4px 8px;
      margin-right: 4px;
    }
  }

  .jurisdiction-dropdown-menu {
    width: auto;

    .options {
      max-height: 50vh;
      overflow-y: auto;

      .option {
        border-bottom: 1px solid $gray-06;
        padding: 4px 16px !important;

        &:hover {
          background-color: $gray-06;
        }

        .checkbox {
          border: 1px solid $gray-50;

          &:checked {
            border: 1px solid $seagreen-100;
          }
        }

        .option-label {
          width: 100%;
        }
      }
    }

    .select-control-items {
      border-top: 1px solid $gray-06;
      padding: 12px 8px !important;

      #select-control-clear-all {
        color: $error-red !important;
      }
    }
  }
}
