@import "../../../styles/scss/variables.scss";

.popover-content {
  .popover-confirm-content {
    .popover-header {
      padding: 10px 0px;
      border: none;
      background-color: transparent;
      font-weight: 500;
    }

    .form-check.input-group {
      margin: 6px 0px;
      display: flex;
      align-items: center;

      .form-check-label {
        font-size: 14px;
      }

      .form-check-input {
        margin-top: 0px;
      }
    }

    .btn {
      border: none;
      height: 25px;
      box-shadow: none;
      padding: 8px 0;
      height: 36px;
      font-size: 0.8rem;
    }

    .primary-btn {
      color: white;
      background-color: $teal-80;
      min-width: 80px;
      border-radius: 3px;
    }

    .primary-btn:hover {
      background-color: $teal-100;
      border-color: $teal-100;
    }

    .primary-btn:active:focus {
      background-color: $teal-100;
      border-color: $teal-100;
    }

    .delete-btn {
      border: none;
      height: 25px;
      box-shadow: none;
      padding: 8px 0;
      height: 36px;
      font-size: 0.8rem;
    }
  }
}

.position-button-wrap {
  align-items: center;
}

.placeholder-text {
  margin: 0 0 0 12px;
  color: #919191;
  font-style: italic;
  font-size: 14px;
}

.position-button {
  padding: 6px 15px;
  width: fit-content;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
}

.statement-box {
  border: 1px solid $gray-30;
  border-radius: 3px;
  padding: 15px;
  font-size: 14px;
  background-color: $gray-05;
}

.font-14 {
  font-size: 14px;
}

.position-statement-subheader {
  font-size: 12px;
  color: $gray-50;
  padding-bottom: 10px;
}