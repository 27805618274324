@import "../../../styles/scss/variables.scss";

.pagination {
  margin: 10px 0;
}

.header {
  padding: 24px 40px 0 40px;
  height: auto;
  display: block;

  @media (max-width: 768px) {
    margin: 10px 0;
  }

  .header-top,
  .header-bottom {
    width: 100%;
    position: relative;
  }

  .header-top {

    .title {
      padding-bottom: 0;
      font-size: 28px;
      line-height: 1.5;
      margin: 0;
    }
  }

  .header-bottom {
    padding: 8px 0;

    .description {
      font-size: 16px;
      padding: 8px 0;
    }

    .right-footer {
      font-size: 12px;
      font-weight: 600;
      float: right;
      color: $medium-grey;
      text-transform: uppercase;

    }

    .description,
    .right-footer {
      @media (max-width: 768px) {
        float: none;
        display: block;
      }
    }
  }
}

.tag {
  background-color: #E8F6FB;
  color: $teal-100;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 12px;
  line-height: 18px;
}

.filters {
  margin: 36px 0;

  .filter {
    align-self: center;
  }
}

.table {
  min-height: 50vh;

  header {
    min-height: auto;
  }

  /* Make space for pagination controls on top of the table header row for react-data-table tables.
  This rule may interact with styles defined on the .rdt_Pagination class */
  >div {
    padding-top: 50px;
  }

  .latest-key-action {
    cursor: pointer;
  }

  >div.tooltip {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 0px;
  }

  .sky-blue {
    color: $sky-blue;
  }

  .rdt_TableHeadRow {
    background-color: #FBFBFB;
    border: none;

    /* Override the rule the applies the pointer cursor to all "button"-y nodes, then hack in
       the pointer cursor onlt to sortable columns (found by the element that indicates sort direction)
    */
    div[role=button] {
      cursor: auto;
    }

    div.rdt_TableCol_Sortable:has(span.__rdt_custom_sort_icon__) {
      cursor: pointer;
    }
  }

  .rdt_TableRow {
    background-color: $white !important;
    box-shadow: 0 1px 0 #E5E5E5 !important;
    padding: 16px 0;

    .rdt_TableCell {
      font-size: 16px;
      font-weight: 400;
      color: black;

      .btn-text {
        font-size: 16px;
      }
    }
  }

  .rdt_TableCol {
    div>div {
      color: #9B9B9B;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
  }


  button.custom-toggle-dropdown-trigger {
    min-width: 24px;
    text-align: center;
  }

}

.grid-card {
  border: 1px solid #EAEAEA;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 24px;
  min-width: 250px;

  &:first-child:last-child {
    width: fit-content;
  }

  .person-image {
    width: 56px;
    height: 56px;
  }

  .overflow-col {
    flex-grow: 0.6;
    padding-top: 8px;

    .grid-card-tag {
      font-size: .9rem;
      font-weight: 600;
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }

  .grid-card-tag {
    background: #F2F2F2;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 12px;
    white-space: nowrap;
  }

  .card-name {
    font-size: 20px;
    padding-top: 16px;
    font-weight: 700;

    a {
      color: black;
      text-decoration: none;
    }
  }

  .card-label {
    color: $gray-40;
    font-size: 12px;
    font-weight: 600;
    padding-top: 16px;
    padding-bottom: 8px;
  }

  .card-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

button.btn-text.saved-search-bell-shared {
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  padding-right: 21px;
  padding-bottom: 0;
  width: 16px;

  &:hover {
    background-color: transparent !important;
  }
}

.expandable-card-container {
  position: relative;
}

.expandable-card {
  max-height: 450px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  position: relative;
  align-items: flex-start;

  &.expanded {
    max-height: 2000px;
    transition: max-height 0.6s ease-in-out;
    align-items: unset;

    & .card-content {
      overflow: visible !important;
      white-space: normal !important;
    }
  }

  a {
    color: $sky-blue;
    font-weight: 600;
  }
}

.overlay-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0) 93%, rgba(255, 255, 255, 1))
}

.expandable-card-footer {
  padding-top: 5px;
}