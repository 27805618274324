@import '../../styles/scss/variables.scss';

.registration {
  overflow: visible;

  .registration-box {
    padding: 20px 60px;

    @media (max-width: 576px) {
      padding: 20px 20px;
    }

    .step-rectangle {
      width: 16px;
      height: 8px;
      margin: 0 8px 0 0;
      border-radius: 4px;
      background-color: $wheat;

      &.active {
        background-color: $butterscotch;
      }
    }

    .registration-header {
      color: black;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .brand-banner{
      img{
        width: 75%;
        display: block;
        margin: 0 auto 3rem auto;
      }
    }

    .registration-subheader {
      color: black;
      text-align: center;
      font-size: 14px;
      margin-bottom: 50px;
    }

    .login-help {
      margin-top: 10px;
    }

    .registration-form {
      margin: 10px auto;
    }

    .previous-step {
      margin: 20px auto;
      color: $medium-grey;
      font-size: 1rem;

      span {
        margin-left: 6px;
        font-size: .875rem;
      }
    }

    .footnote {
      margin-top: 25px;
      text-align: center;
      color: $dark-grey-blue;
      font-weight: 500;
      font-size: .875rem;

      a {
        color: $dark-grey-blue;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }

  .form-section-header {
    margin: 34px 0 25px 0;
    font-size: 1.5rem;
  }

  .registration-form {
    width: 75%;

    .form-field {
      margin: 6px 0 !important;
    }

    .checkbox>.form-field {
      background-color: #ffffff;
    }

    @media (max-width: 576px) {
      width: 100%;
    }

    .dropdown-button {
      min-height: 2rem;
      height: auto;

      .dropdown-title {
        white-space: normal;
      }

        span {
          display: inline-block;
          margin: 5px;
          padding: 2px 12px;
          color: $civic-orange;
          background: #fff4e2;
          border-radius: 12px;
        }

    }

    .dropdown-menu {
      z-index: 98;
      width: 100%;
    }

    .registration-option {
      align-self: start;
      margin-top: 6px;
    }

    .submit>button {
      background-color: $civic-orange !important;
      border-color: $civic-orange !important;
    }

    .error {
      width: 100%;
    }
  }

  .sso-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn-sso {
      border: 1px solid $civic-orange;
    }

    .sso-error {
      margin-top: 20px;
    }
  }

}

.registration-notifications{
  .form-section{
    margin-bottom: 50px;
  }
}
